import { useEffect, useState } from 'react'
import TUMLoading from '../../../components/distinct/management/TeamsUserManagement/TUMLoading/TUMLoading'
import {
	ReturnTypes,
	TUMBuildStatuses,
	TUMPagination,
	TUMPhoneNumberTypes,
	TUMUpdateStatuses,
	TokenType,
} from '../../../utils/enums/enums'
import UseCustomBackendCall from '../../../utils/customHooks/APICalls/UseCustomBackendCall'
import {
	GraphScopes,
	TeamsScopesForTeamsUserMGT,
} from '../../../utils/constants/constants'
import {
	getLocalStorage,
	hasGraphScopes,
	setLocalStorage,
	timeout,
	toAlphaString,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import { ErrorHandling } from '../../../utils/customHooks/ErrorHandling/ErrorHandling'
import {
	CheckAdminRoleRequest,
	DataResponse,
	TeamsUserManagementConfigurationRequest,
	TeamsUserManagementFilter,
	TeamsUserManagementLicenseRequest,
	TeamsUserManagementLicenseResponse,
	TeamsUserManagementPrecheckResponse,
	TeamsUserManagementRequest,
	TeamsUserManagementResponse,
	TeamsUsersResponse,
	TenantConfigurationInfo,
	TUMRangeRequest,
	TUMRangeResponse,
} from '../../../utils/interfaces/APIModels'
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser'
import UseCrud from '../../../utils/customHooks/APICalls/UseCrud'
import {
	DDI,
	DDIRange,
	MSTeamsLicenseSKU,
	MSTeamsUser,
} from '../../../utils/interfaces/DBModels'
import {
	TUMDDIAssignmentRequest,
	TUMDDIsForAssignment,
	TUMPageLink,
	TUMUpdateList,
	TUMUserDDIRangeList,
	TUMVRPList,
	TokenRequest,
} from '../../../utils/interfaces/ComponentModels'
import {
	useGetTenantProvisioningConfigMutation,
	usePerformTeamsUserManagementPrechecksMutation,
} from '../../../services/proxyAPIData'
import TUMDisplay from '../../../components/distinct/management/TeamsUserManagement/TUMDisplay/TUMDisplay'
import UseLocalStorage from '../../../utils/customHooks/LocalStorage/LocalStorage'

function TeamsUserManagement() {
	// **** Steps for this page **** //
	// User needs to login
	// Admin Check
	// Get the tenantID from the loggedInUser to perform prechecks
	// Get the users and licenses

	// **** Variables **** //

	// Global variables

	// Hooks
	const { fetchData } = UseCrud()
	const { fetchCustomData } = UseCustomBackendCall()

	// Error handling
	const addErrorLog = ErrorHandling()

	// API Calls
	const [performPrechecks] = usePerformTeamsUserManagementPrechecksMutation()
	const [getTenantConfigInfo] = useGetTenantProvisioningConfigMutation()

	// MSAL
	const clientId = process.env.REACT_APP_TUM_CLIENT_ID || ''
	const msalInstance = new PublicClientApplication({
		auth: {
			clientId: clientId,
		},
		system: {
			allowRedirectInIframe: true,
		},
	})

	// API Constants
	const [initialGraphToken, setInitialGraphToken] = useState('')
	const [loggedInAccount, setLoggedInAccount] = useState({} as AccountInfo)
	const [msTeamsLicenseSKUList, setMSTeamsLicenseSKUList] = useState(
		[] as MSTeamsLicenseSKU[]
	)
	const [customerID, setCustomerID] = useState('')
	const [serviceID, setServiceID] = useState('')
	const [nextLink, setNextLink] = useState('')

	// Display constants
	const [teamsUserDisplay, setTeamsUserDisplay] = useState(
		[] as TeamsUsersResponse[]
	)
	const [teamsLicenseDisplay, setTeamsLicenseDisplay] = useState(
		{} as TeamsUserManagementLicenseResponse
	)

	// Component constants
	const [tumVRPList, setTUMVRPList] = useState([] as TUMVRPList[])
	const [tumRangeList, setTUMRangeList] = useState([] as TUMUserDDIRangeList[])

	// Tokens - Retrieval
	const [retrievalGraphToken, setRetrievalGraphToken] = useState('')
	const [retrievalTeamsToken, setRetrievalTeamsToken] = useState('')

	// Tokens - Posting to Runbook
	const [postGraphToken, setPostGraphToken] = useState('')
	const [postTeamsToken, setPostTeamsToken] = useState('')

	// Flags
	const [isPageReadyToView, setIsPageReadyToView] = useState(false)
	const [retrievalCallMade, setRetrievalCallMade] = useState(false)
	const [msLicenseSKUCallMade, setMSLicenseSKUCallMade] = useState(false)
	const [retrievalTokenCallMade, setRetrievalTokenCallMade] = useState(false)
	const [vrpCallMade, setVRPCallMade] = useState(false)
	const [adminCheckStatus, setAdminCheckStatus] = useState(
		TUMBuildStatuses.NotYetStarted
	)
	const [adminCheckDesc, setAdminCheckDesc] = useState(
		'Check for valid admin role.'
	)
	const [precheckStatus, setPrecheckStatus] = useState(
		TUMBuildStatuses.NotYetStarted
	)
	const [precheckCheckDesc, setPrecheckCheckDesc] = useState(
		'Perform relevant checks to ensure everything is setup correctly.'
	)
	const [retrievalStatus, setRetrievalStatus] = useState(
		TUMBuildStatuses.NotYetStarted
	)
	const [retrievalCheckDesc, setRetrievalCheckDesc] = useState(
		'Retrieve information from Microsoft such as users and licenses to perform actions on page.'
	)
	const [pageLinks, setPageLinks] = useState([] as TUMPageLink[])
	const [currentPageNo, setCurrentPageNo] = useState(0)
	const [isLastPage, setIsLastPage] = useState(false)

	// Custom local storage hook
	const tumArrayKey = `tum-${customerID}`
	const { addItem } = UseLocalStorage(tumArrayKey)

	// Use Effect 1: Build
	useEffect(() => {
		// Process - Confirmed Account needs to be filled in to start this entire process
		if (initialGraphToken.length > 0) {
			// 1 - Admin Check
			if (adminCheckStatus === TUMBuildStatuses.NotYetStarted) {
				// Make call
				validateAdminRole()
			} else if (adminCheckStatus === TUMBuildStatuses.Success) {
				// 2 - Do prechecks
				if (precheckStatus === TUMBuildStatuses.NotYetStarted) {
					// Make call
					doPrechecks()
				} else if (precheckStatus === TUMBuildStatuses.Success) {
					if (retrievalStatus === TUMBuildStatuses.NotYetStarted) {
						// Retrieve ms teams license SKUs first
						if (!msLicenseSKUCallMade) {
							setMSLicenseSKUCallMade(true)
							getMSTeamsLicenseSKUs()
						}
						// Retrieve VRP list
						if (!vrpCallMade) {
							setVRPCallMade(true)
							getTenantVoiceRouteConfig()
						}
					} else if (retrievalStatus === TUMBuildStatuses.Loading) {
						// Retrieve tokens first
						if (
							retrievalGraphToken.length > 0 &&
							retrievalTeamsToken.length > 0
						) {
							// Make call
							if (!retrievalCallMade) {
								setRetrievalCallMade(true)
								retrieveTeamsUserMGTInfo()
							}
						} else {
							// Retrieve tokens
							if (!retrievalTokenCallMade) {
								setRetrievalTokenCallMade(true)
								retrieveTokensUsingTenantID()
							}
						}
					} else {
						isPageReady()
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		initialGraphToken,
		adminCheckStatus,
		precheckStatus,
		retrievalStatus,
		retrievalGraphToken,
		retrievalTeamsToken,
		retrievalCallMade,
	])

	// Status - Is Page Ready to be viewed (All prechecks done)
	const isPageReady = async () => {
		// Status - If all success then page is ready
		if (
			adminCheckStatus === TUMBuildStatuses.Success &&
			precheckStatus === TUMBuildStatuses.Success &&
			retrievalStatus === TUMBuildStatuses.Success
		) {
			await delayedFunction(3000)
			setIsPageReadyToView(true)
		}
	}

	// *** API Calls ** //
	// GET: Check admin role
	const validateAdminRole = async () => {
		// Loading
		setAdminCheckStatus(TUMBuildStatuses.Loading)
		setAdminCheckDesc('Validating, please wait...')

		// Make call to backend to check admin role
		var requestObj: CheckAdminRoleRequest = {
			GraphToken: initialGraphToken,
		}

		var isValidAdminRole = await fetchCustomData<boolean>({
			QueryURL: 'ValidateAdminRole',
			QueryObj: requestObj,
			ErrorMessage: 'An error occurred when validating role for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: validateAdminRole()',
		})
		if (isValidAdminRole && isValidAdminRole === true) {
			setAdminCheckStatus(TUMBuildStatuses.Success)
			setAdminCheckDesc('Account Role Validated Successfully.')
		} else {
			setAdminCheckStatus(TUMBuildStatuses.Failure)
			setAdminCheckDesc('Invalid role found on your account.')
		}
	}

	// GET: Prechecks
	const doPrechecks = async () => {
		// Placeholder
		setPrecheckStatus(TUMBuildStatuses.Loading)

		// Check for tenantID first
		if (loggedInAccount.tenantId && loggedInAccount.tenantId.length > 0) {
			// First encrypt the tenantID and make call
			var performPrechecksResponse = await performPrechecks(
				toBetaString(loggedInAccount.tenantId)
			)
				.unwrap()
				.catch(() => {
					setPrecheckStatus(TUMBuildStatuses.Failure)
					setPrecheckCheckDesc('Prechecks failed to complete.')
				})
			if (performPrechecksResponse && performPrechecksResponse.Content) {
				// Decrypt
				var decryptedResponse = await toAlphaString(
					performPrechecksResponse?.Content + ''
				)

				// Convert to JSON response
				var prechecksResponseObj = JSON.parse(
					decryptedResponse
				) as TeamsUserManagementPrecheckResponse

				// Check the response: AllPrechecksPassed needs to be true then assign the other variables else return error
				if (
					prechecksResponseObj.allPrechecksPassed &&
					prechecksResponseObj.allPrechecksPassed === true
				) {
					// Assign all relevant information
					// Customer ID
					if (prechecksResponseObj.customerID) {
						setCustomerID(prechecksResponseObj.customerID)

						// Get service ID
						await retrieveServiceIDUsingTenantID(
							prechecksResponseObj.customerID
						)
					}

					// Success response
					setPrecheckCheckDesc('All prechecks have been passed successfully.')
					setPrecheckStatus(TUMBuildStatuses.Success)
				} else {
					setPrecheckStatus(TUMBuildStatuses.Failure)
					setPrecheckCheckDesc(
						`An error occurred: ${prechecksResponseObj.message + ''}.`
					)
				}
			}
		} else {
			// Error
			setRetrievalStatus(TUMBuildStatuses.Failure)
			setRetrievalCheckDesc(
				'There was no tenant ID found in your account to perform prechecks.'
			)
		}
	}

	// GET: MS Licenses SKUs from DB
	const getMSTeamsLicenseSKUs = async () => {
		// Loading
		setRetrievalStatus(TUMBuildStatuses.Loading)
		setRetrievalCheckDesc('Retrieving data, please wait...')

		// Return
		var msTeamsLicenseSKUList = [] as MSTeamsLicenseSKU[]

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getMSTeamsLicenseSKUs()',
			QueryURL: `GetV2?Params=MSTeamsLicenseSKU.All()`,
			ErrorMessage:
				'An error occurred when getting licenses from the DB for teams User MGT',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign the list
			msTeamsLicenseSKUList = dataResponse.Obj
				.MSTeamsLicenseSKUList as MSTeamsLicenseSKU[]

			if (msTeamsLicenseSKUList && msTeamsLicenseSKUList.length > 0) {
				setMSTeamsLicenseSKUList(msTeamsLicenseSKUList)
			}
		}
	}

	// GET: Graph and Teams Token
	const generateRetrievalToken = async (tokenType: number) => {
		// Make call to backend to check admin role
		var requestObj: TokenRequest = {
			TokenType: tokenType,
			TenantID: loggedInAccount.tenantId,
		}

		// Retrieve token
		var token = await fetchCustomData<string>({
			QueryURL: 'GetTokenForTeamsUserManagement',
			QueryObj: requestObj,
			ErrorMessage: 'An error occurred when validating role for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: generateRetrievalToken()',
		})
		if (token && token.length > 0) {
			return token
		} else {
			return null
		}
	}

	// Generate retrieval tokens
	const retrieveTokensUsingTenantID = async () => {
		// Check for tenant ID
		if (loggedInAccount.tenantId && loggedInAccount.tenantId.length > 0) {
			// Graph Token
			var graphToken = await generateRetrievalToken(TokenType.GraphToken)
			if (graphToken && graphToken.length > 0) {
				setRetrievalGraphToken(graphToken)
			}

			// Teams Token
			var teamsToken = await generateRetrievalToken(TokenType.TeamsToken)
			if (teamsToken && teamsToken.length > 0) {
				setRetrievalTeamsToken(teamsToken)
			}

			if (!teamsToken || !graphToken) {
				// Error
				setRetrievalStatus(TUMBuildStatuses.Failure)
				setRetrievalCheckDesc(
					'An error occurred when generating tokens to retrieve your information from Microsoft.'
				)
			}
		} else {
			// Error
			setRetrievalStatus(TUMBuildStatuses.Failure)
			setRetrievalCheckDesc(
				'There was no tenant ID found in your account to retrieve your information from Microsoft.'
			)
		}
	}

	// GET: Service ID
	const retrieveServiceIDUsingTenantID = async (customerID: string) => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName:
				'TeamsUserManagement.tsx: retrieveServiceIDUsingTenantID()',
			QueryURL: `GetV2?Params=MSTeamsUser.First(MSTeamsUser.CustomerID = '${customerID}' & MSTeamsUser.TenantID = '${loggedInAccount.tenantId}')`,
			ErrorMessage: 'An error occurred when service ID for customer',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign the object
			var msTeamsUserObj = dataResponse.Obj.MSTeamsUser as MSTeamsUser

			if (msTeamsUserObj && msTeamsUserObj.ServiceID) {
				setServiceID(msTeamsUserObj.ServiceID)
			}
		}
	}

	// GET: Return tenant config for VRP
	const getTenantVoiceRouteConfig = async () => {
		// List to build
		var vrpList = [] as TUMVRPList[]

		// Encrypt customerID and make call
		await getTenantConfigInfo(toBetaString(customerID))
			.unwrap()
			.then(async (response) => {
				// Decrypt response and assign to object
				if (response && response.Content) {
					var decryptedResponse = await toAlphaString(response.Content + '')
					var responseObj = JSON.parse(
						decryptedResponse
					) as TenantConfigurationInfo

					// Check response to get regions and then get the vrp
					if (
						responseObj &&
						responseObj.Regions &&
						responseObj.Regions.length > 0
					) {
						// Loop through and buildVRP list
						for (let reg = 0; reg < responseObj.Regions.length; reg++) {
							// Add object
							vrpList.push({
								CountryISO2Code: responseObj.Regions[reg].CountryISO2,
								DomVoiceRoute: responseObj.Regions[reg].DomVoiceRoute,
								DomVoiceRoutingPolicy:
									responseObj.Regions[reg].DomVoiceRoutingPolicy,
								IntVoiceRoute: responseObj.Regions[reg].IntVoiceRoute,
								IntVoiceRoutingPolicy:
									responseObj.Regions[reg].IntVoiceRoutingPolicy,
							})
						}
					}
				}
			})
			.catch((error) => {
				// Error
				if (error) {
					setRetrievalStatus(TUMBuildStatuses.Failure)
					setRetrievalCheckDesc(
						'An error occurred when getting your voice routing policies.'
					)
				}
			})

		// Set list built
		setTUMVRPList(vrpList)
	}

	// GET: Retrieve all the teams user MGT information for user
	const retrieveTeamsUserMGTInfo = async () => {
		// Make calls
		var licenseSuccess = await getTeamsLicenses()
		var userSuccess = await getTeamsUsers()

		if (!userSuccess) {
			// Error
			setRetrievalStatus(TUMBuildStatuses.Failure)
			setRetrievalCheckDesc(
				'An error occurred when trying to retrieve users from Microsoft.'
			)
			return
		}

		if (!licenseSuccess) {
			// Error
			setRetrievalStatus(TUMBuildStatuses.Failure)
			setRetrievalCheckDesc(
				'An error occurred when trying to retrieve licenses from Microsoft.'
			)
			return
		}

		// After the calls, if no errors set success
		if (licenseSuccess && userSuccess) {
			setRetrievalCheckDesc(
				'All information retrieved successfully. Page will load shortly.'
			)
			setRetrievalStatus(TUMBuildStatuses.Success)
		}
	}

	// GET: Users from MS Call
	const getTeamsUsers = async (
		requestURL?: string,
		filter?: TeamsUserManagementFilter
	): Promise<boolean> => {
		// Make call to backend to check admin role
		var requestObj: TeamsUserManagementRequest = {
			GraphToken: retrievalGraphToken,
			TeamsToken: retrievalTeamsToken,
			MSTeamsLicenseSKUs: msTeamsLicenseSKUList,
			RequestURL: requestURL && requestURL.length > 0 ? requestURL : '',
			Filter: filter,
		}

		var teamsUserResponse = await fetchCustomData<TeamsUserManagementResponse>({
			QueryURL: 'GetUsersFromMicrosoft',
			QueryObj: requestObj,
			ErrorMessage:
				'An error occurred when retrieving users from Microsoft for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: getTeamsUsers()',
		})
		if (teamsUserResponse) {
			// Check for users
			if (
				teamsUserResponse.usersResponse &&
				teamsUserResponse.usersResponse.length > 0
			) {
				// Set the display - Order it first
				teamsUserResponse.usersResponse.sort((a, b) =>
					(a.name + '').toLowerCase().localeCompare(b.name + '')
				)
				setTeamsUserDisplay(teamsUserResponse.usersResponse)
			}

			// Next and Previous Link
			if (teamsUserResponse.nextLink && teamsUserResponse.nextLink.length > 0) {
				setNextLink(teamsUserResponse.nextLink)
				setIsLastPage(false)
			} else {
				setIsLastPage(true)
			}
			if (
				teamsUserResponse.previousLink &&
				teamsUserResponse.previousLink.length > 0
			) {
				var currentLink = teamsUserResponse.previousLink

				// Set the page link if not existing
				if (!pageLinks.find((l) => l.PageLink === currentLink)) {
					setPageLinks((prevPageLinks) => [
						...prevPageLinks,
						{ PageLink: currentLink, PageNo: Number(prevPageLinks.length) + 1 },
					])
				}
			}

			return true
		} else {
			// Error
			return false
		}
	}

	// GET: Licenses from MS Call
	const getTeamsLicenses = async (): Promise<boolean> => {
		// Make call to backend to check admin role
		var requestObj: TeamsUserManagementLicenseRequest = {
			GraphToken: retrievalGraphToken,
			MSTeamsLicenseSKUs: msTeamsLicenseSKUList,
		}

		var teamsLicenseResponse =
			await fetchCustomData<TeamsUserManagementLicenseResponse>({
				QueryURL: 'GetLicensesFromMicrosoft',
				QueryObj: requestObj,
				ErrorMessage:
					'An error occurred when validating role for Teams User MGT',
				ShowErrorToast: false,
				ShowSuccessToast: false,
				LogErrorToDB: true,
				FileAndFunctionName: 'Home.tsx: getUsers()',
			})
		if (teamsLicenseResponse) {
			// Check for users
			setTeamsLicenseDisplay(teamsLicenseResponse)

			return true
		} else {
			// Error
			return false
		}
	}

	// GET: Retrieve ranges from the DB based on customerID
	const getDDIRanges = async () => {
		// Return
		var tumRangeResponseList = [] as TUMUserDDIRangeList[]

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIRanges()',
			QueryURL: `GetV2?Params=DDIRange.Where(DDIRange.CustomerID = '${customerID}')`,
			ErrorMessage: `An error occurred when retrieving DDI ranges for customer ${customerID}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign response
			var ddiRangeResponse = dataResponse.Obj.DDIRangeList as DDIRange[]

			if (ddiRangeResponse && ddiRangeResponse.length > 0) {
				// Loop through and build display
				var ddiRangeList = [] as TUMUserDDIRangeList[]
				for (let range = 0; range < ddiRangeResponse.length; range++) {
					// Push to list
					ddiRangeList.push({
						DDIRangeID: ddiRangeResponse[range].DDIRangeID,
						DDIRangeStart: ddiRangeResponse[range].DDIRangeStart,
						DDIRangeEnd: ddiRangeResponse[range].DDIRangeEnd,
						Total: getPhoneNumberDifference(
							ddiRangeResponse[range].DDIRangeStart + '',
							ddiRangeResponse[range].DDIRangeEnd + ''
						),
					})
				}

				tumRangeResponseList = ddiRangeList
			}
		}

		// Set value and return
		setTUMRangeList(tumRangeResponseList)
		return tumRangeResponseList
	}

	// GET: Retrieve list of numbers that are already assigned based on range selected
	const getUserAssignedNumbers = async (ddiRangeID: number) => {
		// Return
		var tumRangeResponse = [] as TUMRangeResponse[]

		// Get the current range
		var currentRange = tumRangeList.find(
			(range) => Number(range.DDIRangeID) === ddiRangeID
		)
		if (currentRange) {
			// Build object for MS API Call
			var requestObj: TUMRangeRequest = {
				TeamsToken: retrievalTeamsToken,
				RangeStart: currentRange.DDIRangeStart,
				RangeEnd: currentRange.DDIRangeEnd,
			}

			// Make call
			var apiResponse = await fetchCustomData<TUMRangeResponse[]>({
				QueryURL: 'GetUsersWithAssignedNumbers',
				QueryObj: requestObj,
				ErrorMessage:
					'An error occurred when retrieving users with assigned numbers for Teams User MGT',
				ShowErrorToast: false,
				ShowSuccessToast: false,
				LogErrorToDB: true,
				FileAndFunctionName: 'TeamsUserManagement.tsx: getTeamsUsers()',
			})
			if (apiResponse) {
				tumRangeResponse = apiResponse
			}
		}

		return tumRangeResponse
	}

	// GET: Retrieve list of DDIs for assignment based on range selected - filter out the numbers already assigned
	const getDDIsForAssignment = async (
		ddiAssignmentRequest: TUMDDIAssignmentRequest
	) => {
		// Return
		var ddisForAssignmentList = [] as TUMDDIsForAssignment[]

		// Filters
		var queryFilters = `DDIRange.CustomerID = '${customerID}' & DDIRange.DDIRangeID = '${ddiAssignmentRequest.DDIRangeID}'`
		// 1 - DDI Range filter
		if (ddiAssignmentRequest.AssignedNumbersFilter) {
			queryFilters += ` & DDI.DDI !^ '${ddiAssignmentRequest.AssignedNumbersFilter}'`
		}
		// 2 - Search DDI filter (If the user searches)
		if (ddiAssignmentRequest.DDISearchValue) {
			queryFilters += ` & DDI.DDI ~ '${ddiAssignmentRequest.DDISearchValue}'`
		}

		// Build query URL
		var queryURL = `GetV2?Params=DDIRange.DDI.Address.State.Country.Where(${queryFilters}).Pagination(NumberOfRows = '10' & PageNumber = '1' & Column = 'DDI.DDI' & SortType = 'ASC')`

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIsForAssignment()',
			QueryURL: queryURL,
			ErrorMessage: `An error occurred when retrieving DDI ranges for customer ${customerID}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign
			var ddiRangeResponse = dataResponse.Obj.DDIRangeList as DDIRange[]

			if (ddiRangeResponse && ddiRangeResponse.length > 0) {
				// Loop through response to build list to be assigned
				for (let range = 0; range < ddiRangeResponse.length; range++) {
					// Assign the DDI list and adjust
					var ddiList = ddiRangeResponse[range].DDIList as DDI[]

					if (ddiList && ddiList.length > 0) {
						// Loop through DDI List and build the display list
						for (let ddi = 0; ddi < ddiList.length; ddi++) {
							// Add DDI and country code to list
							ddisForAssignmentList.push({
								DDI: ddiList[ddi].DDI + '',
								CountryISO2Code:
									ddiList[ddi].Address?.State?.Country?.CountryISO2Code + '',
								NumberType: ddiList[ddi].ServiceTypeOutID,
							})
						}
					}
				}
			}
		}

		return ddisForAssignmentList
	}

	// GET: Service Type ID for DDI (TDR/OC)
	const getDDIServiceTypeID = async (ddi: string) => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIServiceTypeID()',
			QueryURL: `GetV2?Params=DDI.First(DDI.DDI = '${ddi}')`,
			ErrorMessage: `An error occurred when retrieving DDI information using ${ddi}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && dataResponse.Obj) {
			// Assign response
			var ddiResponse = dataResponse.Obj.DDI as DDI
			if (ddiResponse && ddiResponse.ServiceTypeOutID) {
				// Return ID
				return ddiResponse.ServiceTypeOutID
			}
		}

		// Empty return
		return TUMPhoneNumberTypes.TDR
	}

	// POST: Teams User MGT Configuration
	const configureTeamsUser = async (
		configurationList: TeamsUserManagementConfigurationRequest[]
	) => {
		// Generate tokens if not already generated
		var graphToken: string | null | void = null
		var teamsToken: string | null | void = null
		if (postGraphToken.length > 0) {
			// Validate
			graphToken = postGraphToken
		} else {
			// Generate graph token
			graphToken = await generateToken(TokenType.GraphToken)

			if (graphToken) {
				setPostGraphToken(graphToken)
			} else {
				return false
			}
		}
		if (postTeamsToken.length > 0) {
			// Validate
			teamsToken = postTeamsToken
		} else {
			// Generate teams token
			teamsToken = await generateToken(TokenType.TeamsToken)

			if (teamsToken) {
				setPostTeamsToken(teamsToken)
			} else {
				return false
			}
		}

		// Amend the list to add relevant information
		var amendedConfigList = configurationList.map((config) => ({
			...config,
			CustomerID: customerID,
			GraphToken: graphToken,
			TeamsToken: teamsToken,
			ServiceID: serviceID,
		}))

		// Make call
		var response = await fetchCustomData<boolean>({
			QueryURL: `PostTeamsUserManagementConfig`,
			QueryObj: amendedConfigList,
			ErrorMessage: 'An error occurred when attempting to configure user',
			ShowErrorToast: false,
			ShowSuccessToast: true,
			SuccessMessage: 'Configuration successfully sent to be completed',
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: configureTeamsUser()',
		})

		if (response && response === true) {
			// Add to the TUM status array
			// First check if the TUM status array is created for this specific customer (using customerID in the key name of array)

			// Variables
			var localTUMUpdateList = getLocalStorage(tumArrayKey)
			var tumUpdateObj: TUMUpdateList = {}

			// If not found in local storage, we need to create the array first
			if (!localTUMUpdateList) {
				// Create empty array and add to local storage
				var emptyArray: TUMUpdateList[] = []
				setLocalStorage(tumArrayKey, emptyArray)
			}

			// Get current date and time
			var logTime = getCurrentDateTime()

			// Loop through config list and add to local storage
			for (let config = 0; config < amendedConfigList.length; config++) {
				// Create obj to add to list
				tumUpdateObj = {
					JobID: amendedConfigList[config].JobID,
					UPN: amendedConfigList[config].UPN,
					ActionPerformed: amendedConfigList[config].ActionTypeID,
					Status: TUMUpdateStatuses.Pending,
					LogTime: logTime,
				}
				addItem(tumUpdateObj)
			}
		}
	}

	// *** MSAL Functions *** //
	// Handle login using MSAL
	const handleLogin = async () => {
		// Initialize instance
		await msalInstance.initialize()

		// Login Request
		const logInRequest = {
			scopes: GraphScopes,
			prompt: 'select_account',
		}

		await msalInstance
			.loginPopup(logInRequest)
			.then(async (response) => {
				if (response) {
					// Use access token to check for admin role
					if (response.accessToken) {
						setInitialGraphToken(response.accessToken)
					}

					setLoggedInAccount(response.account)
				} else {
					// No token retrieved
					setAdminCheckStatus(TUMBuildStatuses.Failure)
					setAdminCheckDesc(
						'An error occurred when retrieving your account token to perform this check.'
					)
				}
			})
			.catch(async (error) => {
				// Log error to DB - If user does not cancel the process
				if (!(error?.errorMessage + '').includes('User cancelled')) {
					await addErrorLog({
						ActionPerformed: 'MSAL Login Error on Teams User MGT',
						ErrorInfo: `Error occurred when logging in to Teams User MGT: ${JSON.stringify(
							error
						)}`,
					})
				}
			})
	}

	// Generate token from MSAL using logged in account (confirmed account)
	const generateToken = async (tokenType: number) => {
		// Error handling
		var isError = false
		var errorMessage = ''
		var token = ''

		var scopes = [] as string[]
		switch (tokenType) {
			// Graph
			case TokenType.GraphToken:
				scopes = GraphScopes
				break
			// Teams
			case TokenType.TeamsToken:
				scopes = TeamsScopesForTeamsUserMGT
				break
		}

		// Initialize instance
		await msalInstance.initialize()

		return await msalInstance
			.acquireTokenSilent({
				scopes: scopes,
				account: loggedInAccount,
			})
			.then(async (response) => {
				// Response token
				if (response) {
					if (tokenType === TokenType.GraphToken) {
						// Check if it has graph scopes
						if (hasGraphScopes(response.scopes)) {
							token = response.accessToken
						}
					} else {
						token = response.accessToken
					}
				}

				// Return true
				return token
			})
			.catch(async (error) => {
				if (error) {
					isError = true
					errorMessage = JSON.stringify(error)
				}
			})
			.finally(() => {
				if (isError) {
					// Log Error to DB
					addErrorLog({
						ActionPerformed: 'Teams User MGT: Token Generation',
						ErrorInfo: `An error occurred when retrieving ${TokenType[tokenType]}: ${errorMessage}`,
					})
				}
			})
	}

	// Handle navigation between pages
	const handlePageNavigation = async (navType: number) => {
		// Request URL
		var requestURL = ''

		// First check whether it is next or prev
		switch (navType) {
			case TUMPagination.Next:
				// Set Request URL
				requestURL = nextLink
				// Current page
				setCurrentPageNo((prev) => prev + 1)

				break

			case TUMPagination.Previous:
				// Get the request URL from the array
				var getPageLink = pageLinks.find((l) => l.PageNo === currentPageNo)
				if (getPageLink) {
					requestURL = getPageLink.PageLink
				}
				setCurrentPageNo((prev) => prev - 1)

				break
		}

		// Make call
		var userSuccess = await getTeamsUsers(requestURL)

		return userSuccess
	}

	// Get date
	const getCurrentDateTime = (): Date => {
		return new Date()
	}

	// Function to get the difference between DDI range start and end
	const getPhoneNumberDifference = (start: string, end: string): number => {
		const startNumber = parseInt(start, 10)
		const endNumber = parseInt(end, 10)
		return endNumber - startNumber
	}

	// Placeholder delay
	const delayedFunction = async (time: number) => {
		// Delay
		await timeout(time)
	}

	return isPageReadyToView ? (
		<>
			<TUMDisplay
				// Customer ID
				customerID={customerID}
				// Displays
				licenseDisplay={teamsLicenseDisplay}
				usersDisplay={teamsUserDisplay}
				tumVRPList={tumVRPList}
				// Flags
				currentPageNo={currentPageNo}
				isLastPage={isLastPage}
				handlePageNavigation={handlePageNavigation}
				// API functions
				getTeamsUsers={getTeamsUsers}
				getTeamsLicenses={getTeamsLicenses}
				getDDIRanges={getDDIRanges}
				getUserAssignedNumbers={getUserAssignedNumbers}
				getDDIsForAssignment={getDDIsForAssignment}
				getDDIServiceTypeID={getDDIServiceTypeID}
				configureTeamsUser={configureTeamsUser}
			/>
		</>
	) : (
		<>
			<TUMLoading
				generalStatus={0}
				loginStatus={adminCheckStatus}
				loggedInAccount={loggedInAccount.username}
				tumLoadingRows={[
					{
						Heading: 'Admin Role Check',
						Description: adminCheckDesc,
						Status: adminCheckStatus,
					},
					{
						Heading: 'Prechecks',
						Description: precheckCheckDesc,
						Status: precheckStatus,
					},
					{
						Heading: 'Retrieve Information',
						Description: retrievalCheckDesc,
						Status: retrievalStatus,
					},
				]}
				handleLogin={handleLogin}
			/>
		</>
	)
}

export default TeamsUserManagement
