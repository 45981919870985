import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import Preloader from '../../components/shared/displays/Preloader/Preloader'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useAppDispatch } from '../../store/hooks'
import {
	setAccessToken,
	setLoggedInUser,
	setLoggedInUserRolesPermissions,
	setRoleID,
} from '../../store/reducers/reducers'
import { useEffect, useState } from 'react'
import UseCustomBackendCall from '../../utils/customHooks/APICalls/UseCustomBackendCall'
import {
	DataResponse,
	GetUsersByOrgIDRequestModel,
	User,
} from '../../utils/interfaces/APIModels'
import UseCrud from '../../utils/customHooks/APICalls/UseCrud'
import { ReturnTypes } from '../../utils/enums/enums'
import { RolePermissionMap } from '../../utils/interfaces/DBModels'
import { Dashboard } from '../../components/distinct/home/Dashboard/Dashboard'
import './Home.scss'
import Login from '../login/Login'
import { ErrorDisplay } from '../../components/shared/displays/ErrorDisplay/ErrorDisplay'

function Home() {
	// Global variables
	const accessToken = useSelector(
		(state: RootState) => state.RootReducer.accessTokenReducer.value
	)
	const organizationID = useSelector(
		(state: RootState) => state.RootReducer.organizationIDReducer.value
	)

	// Hooks
	const {
		fetchCustomData,
		loading: customDataLoading,
		error: customDataError,
	} = UseCustomBackendCall()
	const { fetchData, loading, error } = UseCrud()

	// Display constants
	const [errorMessage, setErrorMessage] = useState('')

	// Logic variables
	const [users, setUsers] = useState([] as User[])
	const [dataLoaded, setDataLoaded] = useState(false)

	// Flags
	const isLocalHost = window.location.host.includes('localhost')
	const [usersCallMade, setUsersCallMade] = useState(false)
	const [rolesAndPermissionsRetrieved, setRolesAndPermissionsRetrieved] =
		useState(false)

	// Auth0
	const {
		isAuthenticated,
		user,
		error: auth0Error,
		getAccessTokenWithPopup,
		getAccessTokenSilently,
		isLoading: isAuth0Loading,
	} = useAuth0()
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		// Make call if nothing else is happening
		if (isAuthenticated && !auth0Error) {
			// Check the access token
			if (accessToken.length === 0) {
				// Get access token
				getAccessToken()
			} else {
				// Use access token to make call and get users
				if (users.length === 0) {
					if (!usersCallMade) {
						setUsersCallMade(true)
						getUsers()
					}
				} else {
					// Make call to build roles and permissions
					if (!rolesAndPermissionsRetrieved && users.length > 0) {
						setRolesAndPermissionsRetrieved(true)
						getRolesAndPermissions()
					}
				}
			}
		} else {
			// Auth0 error
			if (auth0Error) {
				handleAuth0Error()
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isAuthenticated,
		auth0Error,
		error,
		customDataError,
		users,
		errorMessage,
		usersCallMade,
		accessToken,
	])

	// GET: Access token for Auth0
	const getAccessToken = async () => {
		// Make call only if the access token is null
		var token = ''
		const scope =
			'read:users read:role read:organizations_summary read:organization_member_roles read:organization_invitations read:organizations read:user delete:user add:user'
		if (isLocalHost) {
			var getTokenWithPopup = await getAccessTokenWithPopup({
				authorizationParams: {
					audience: audience,
					scope: scope,
					prompt: 'consent',
				},
			})
			if (getTokenWithPopup) {
				token = getTokenWithPopup
			}
		} else {
			var getTokenSilently = await getAccessTokenSilently({
				authorizationParams: {
					audience: audience,
					scope: scope,
				},
			})
			if (getTokenSilently) {
				token = getTokenSilently
			}
		}
		dispatch(setAccessToken(token))
	}

	// GET: Get users from Auth0
	const getUsers = async () => {
		// Generate obj
		var getUsersByOrgIDRequestModel: GetUsersByOrgIDRequestModel = {
			OrganizationID: organizationID,
			UserEmails: [user?.email + ''],
			AuthorizationToken: accessToken,
		}

		// Get users
		var users = await fetchCustomData<User[]>({
			QueryURL: 'GetUsersByOrgID',
			QueryObj: getUsersByOrgIDRequestModel,
			ErrorMessage: 'An error occurred when getting users from auth0',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'Home.tsx: getUsers()',
		})
		if (users) {
			setUsers(users)
		} else {
			setErrorMessage(
				'Sorry, there seems to be a problem with your account. Please contact support for assistance.'
			)
		}
	}

	// GET: Roles and permissions
	const getRolesAndPermissions = async () => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'Home.tsx: getRolesAndPermissions()',
			QueryURL: `GetV2?Params=RolePermissionMap.Role.Where(Role.IsInternal = '0')`,
			ErrorMessage:
				'An error occurred when getting roles and permissions for users',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Get the roles permission list
			var rolePermissionMapList = dataResponse.Obj
				.RolePermissionMapList as RolePermissionMap[]

			if (rolePermissionMapList && users && user) {
				// Get the logged in user from users list returned
				var loggedInUser = users.find((x) => x.email === user.email)
				if (loggedInUser) {
					// Dispatch to global state
					dispatch(setLoggedInUser(loggedInUser))
					var userRolesAndPermissions = rolePermissionMapList.filter(
						(rolePermission) =>
							loggedInUser?.roles.find(
								(authRole) => authRole.name === rolePermission.Role?.RoleName
							)
					)
					// Dispatch role ID to state to be used by other components
					dispatch(setRoleID(Number(userRolesAndPermissions[0].RoleID)))
					// Dispatch user permissions to check what they have access to
					dispatch(setLoggedInUserRolesPermissions(userRolesAndPermissions))
				}
			}
		}
		setDataLoaded(true)
	}

	// Handle function - Auth0 error
	const handleAuth0Error = () => {
		if (auth0Error) {
			var errorMessage = ''
			switch (true) {
				case auth0Error.message.trim().includes('is not part of'):
					errorMessage = 'Login failed. Please try again.'
					break

				case auth0Error.message
					.trim()
					.includes('invitation not found or already used'):
					errorMessage =
						'The invitation for the account you logged in was not found or it has already been used.'
					break

				default:
					errorMessage =
						'There was an issue when logging you in. Please try to log in again.'
					break
			}
			setErrorMessage(errorMessage)
		}
	}

	return !isAuthenticated ? (
		<Login errorMessage={errorMessage} />
	) : isAuthenticated &&
	  user &&
	  errorMessage.length === 0 &&
	  dataLoaded &&
	  !isAuth0Loading &&
	  !loading &&
	  !customDataLoading ? (
		<>
			<Box className='home-container'>
				{/* ** For each user type - They will see a different view ** */}
				{/* Show Temp Display for now */}
				<Dashboard />
			</Box>
		</>
	) : errorMessage.length > 0 ? (
		<>
			{/* Error display */}
			<ErrorDisplay
				errorTitle='An error occurred'
				errorDescription={errorMessage}
			/>
		</>
	) : (
		<Preloader />
	)
}

export default Home
