import { Box, Typography } from '@mui/material'

const PhoneNumberTip = () => {
	return (
		<>
			<Box className='tum-tooltip-container'>
				{/* Heading */}
				<Box className='tooltip-title'>
					{/* Heading */}
					<Typography component='h5'>Phone Number Information</Typography>
					{/* Description */}
					<Typography component='p'>
						First select the range of TNs you would like to allocate a number
						from. The system will then return a list of free numbers to select
						from. You can also search for a number from that range by typing it
						in.
					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default PhoneNumberTip
