import { useEffect, useState } from 'react'
import './PartnerDashboard.scss'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { ReturnTypes, Roles } from '../../../../../utils/enums/enums'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	Box,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import { useNavigate } from 'react-router'
import CallMadeIcon from '@mui/icons-material/CallMade'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useAuth0 } from '@auth0/auth0-react'

export const PartnerDashboard = () => {
	// Global variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	// Auth0
	const { logout } = useAuth0()

	// Hooks
	const { fetchData } = UseCrud()

	// Display constants
	const [totalOrderCount, setTotalOrderCount] = useState(0)
	const [totalCustomerCount, setTotalCustomerCount] = useState(0)
	const [totalLeadsCount, setTotalLeadsCount] = useState(0)

	// Flags
	const [countCallMade, setCountCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(0)

	// General
	const navigate = useNavigate()

	// Theme
	const theme = useTheme()

	useEffect(() => {
		// Make initial call to return count
		if (!countCallMade && roleID === Roles.PartnerAdmin) {
			setCountCallMade(true)
			getCounts()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countCallMade])

	// GET: Card counts
	const getCounts = async () => {
		// Make hook call - Order Count
		var orderDataResponse = (await fetchData({
			FileAndFunctionName: 'TempDisplay.tsx: getCounts()',
			QueryURL: `GetV2?Params=Order.Customer.CustomerPartner.Exists(Customer.CustomerPartnerID= '${partnerID}' & Order.ExternalOrderID ~ 'ORD')`,
			ErrorMessage: 'An error occurred when getting order count',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (Number(orderDataResponse.Count) > 0) {
			setTotalOrderCount(Number(orderDataResponse.Count))
		}

		// Call 1 Done
		setAPICallsDone(1)

		// Make hook call - Customer Count
		var customerDataResponse = (await fetchData({
			FileAndFunctionName: 'TempDisplay.tsx: getCounts()',
			QueryURL: `GetV2?Params=Customer.CustomerPartner.Exists(Customer.CustomerPartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting customer count',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (Number(customerDataResponse.Count) > 0) {
			setTotalCustomerCount(Number(customerDataResponse.Count))
		}

		// Call 2 Done
		setAPICallsDone(2)

		// Make hook call - Leads Count
		var leadsDataResponse = (await fetchData({
			FileAndFunctionName: 'TempDisplay.tsx: getCounts()',
			QueryURL: `GetV2?Params=Lead.Exists(Lead.PartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting customer count',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (Number(leadsDataResponse.Count) > 0) {
			setTotalLeadsCount(Number(leadsDataResponse.Count))
		}

		// Call 3 Done
		setAPICallsDone(3)
	}

	return (
		<>
			<Box className='temp-container'>
				{/* Partner Display */}
				{/* 1 - Numbers */}
				<Box className='temp-p-content cnt'>
					{/* Order */}
					<Box
						className='temp-card'
						sx={{
							background: theme.palette.secondary.main,
						}}>
						{/* Icon and button */}
						<Box className='temp-card-header'>
							{/* Icon */}
							<Box className='temp-card-icon'>
								<ListAltOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
								/>
							</Box>
							{/* Button */}
							<Tooltip title='Proceed to Order Management'>
								<IconButton
									onClick={() => navigate('/order-management')}
									sx={{ background: theme.palette.primary.main }}>
									<CallMadeIcon
										sx={{ color: theme.palette.primary.contrastText }}
									/>
								</IconButton>
							</Tooltip>
						</Box>
						{/* Content */}
						<Box className='temp-card-content'>
							{/* Count and label */}
							<Box className='temp-card-cnt-label'>
								{/* Count */}
								{apiCallsDone >= 1 ? (
									<Typography component='h2'>{totalOrderCount}</Typography>
								) : (
									<CircularProgress size={52} color='inherit' />
								)}
								{/* Label */}
								<Typography component='h5'>Total Orders</Typography>
							</Box>
							{/* Description */}
							<Typography component='p'>
								Proceed to Order Management to view all your customer orders
							</Typography>
						</Box>
					</Box>
					{/* Customer */}
					<Box
						className='temp-card'
						sx={{
							background: theme.palette.secondary.main,
						}}>
						{/* Icon and button */}
						<Box className='temp-card-header'>
							{/* Icon */}
							<Box className='temp-card-icon'>
								<PersonSearchOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
								/>
							</Box>
							{/* Button */}
							<Tooltip title='Proceed to Customer Management'>
								<IconButton
									onClick={() => navigate('/customer-management')}
									sx={{ background: theme.palette.primary.main }}>
									<CallMadeIcon
										sx={{ color: theme.palette.primary.contrastText }}
									/>
								</IconButton>
							</Tooltip>
						</Box>
						{/* Content */}
						<Box className='temp-card-content'>
							{/* Count and label */}
							<Box className='temp-card-cnt-label'>
								{/* Count */}
								{apiCallsDone >= 2 ? (
									<Typography component='h2'>{totalCustomerCount}</Typography>
								) : (
									<CircularProgress size={52} color='inherit' />
								)}
								{/* Label */}
								<Typography component='h5'>Total Customers</Typography>
							</Box>
							{/* Description */}
							<Typography component='p'>
								Proceed to Customer Management to view and manage all your
								customers
							</Typography>
						</Box>
					</Box>
					{/* Leads */}
					<Box
						className='temp-card'
						sx={{
							background: theme.palette.secondary.main,
						}}>
						{/* Icon and button */}
						<Box className='temp-card-header'>
							{/* Icon */}
							<Box className='temp-card-icon'>
								<SettingsApplicationsOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
								/>
							</Box>
							{/* Button */}
							<Tooltip title='Proceed to Lead Management'>
								<IconButton
									onClick={() => navigate('/leads-management')}
									sx={{ background: theme.palette.primary.main }}>
									<CallMadeIcon
										sx={{ color: theme.palette.primary.contrastText }}
									/>
								</IconButton>
							</Tooltip>
						</Box>
						{/* Content */}
						<Box className='temp-card-content'>
							{/* Count and label */}
							<Box className='temp-card-cnt-label'>
								{/* Count */}
								{apiCallsDone >= 3 ? (
									<Typography component='h2'>{totalLeadsCount}</Typography>
								) : (
									<CircularProgress size={52} color='inherit' />
								)}
								{/* Label */}
								<Typography component='h5'>Total Leads</Typography>
							</Box>
							{/* Description */}
							<Typography component='p'>
								Proceed to Lead Management to view and manage all your leads
							</Typography>
						</Box>
					</Box>
				</Box>
				{/* 2 - Settings and logout */}
				<Box className='temp-p-content st'>
					{/* Settings */}
					<Box
						className='temp-card'
						sx={{
							background: '#F5F5F5',
						}}>
						{/* Icon and button */}
						<Box className='temp-card-header'>
							{/* Icon */}
							<Box className='temp-card-icon'>
								<SettingsOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
								/>
							</Box>
							{/* Button */}
							<Tooltip title='Proceed to Settings'>
								<IconButton
									onClick={() => navigate('/settings')}
									sx={{ background: theme.palette.primary.main }}>
									<CallMadeIcon
										sx={{ color: theme.palette.primary.contrastText }}
									/>
								</IconButton>
							</Tooltip>
						</Box>
						{/* Content - Split to show primary and secondary colour */}
						<Box className='temp-card-two'>
							{/* Content Text */}
							<Box className='temp-card-content st'>
								{/* Label */}
								<Typography component='h4'>Settings</Typography>
								{/* Description */}
								<Typography component='p'>
									Proceed to Settings to view and update your website appearance
									as well as manage your portal users.
								</Typography>
							</Box>
							{/* Colour Palette */}
							<Box className='site-col'>
								{/* Palette */}
								<Box className='temp-colour-palette'>
									{/* Primary */}
									<Box className='col' bgcolor={theme.palette.primary.main}>
										<Typography color={theme.palette.primary.contrastText}>
											Primary
										</Typography>
									</Box>
									{/* Secondary */}
									<Box className='col' bgcolor={theme.palette.secondary.main}>
										<Typography color={theme.palette.secondary.contrastText}>
											Secondary
										</Typography>
									</Box>
								</Box>
								{/* Text */}
								<Typography className='desc' component='p'>
									The above shows the primary and secondary colour of the site.
									You can change this on the settings page.
								</Typography>
							</Box>
						</Box>
					</Box>
					{/* Logout */}
					<Box
						className='temp-card'
						sx={{
							background: theme.palette.secondary.main,
						}}>
						{/* Icon and button */}
						<Box className='temp-card-header'>
							{/* Icon */}
							<Box className='temp-card-icon'>
								<LogoutOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
								/>
							</Box>
							{/* Button */}
							<Tooltip title='Logout of Portal'>
								<IconButton
									onClick={() =>
										logout({
											logoutParams: { returnTo: window.location.origin },
										})
									}
									sx={{ background: theme.palette.primary.main }}>
									<CallMadeIcon
										sx={{ color: theme.palette.primary.contrastText }}
									/>
								</IconButton>
							</Tooltip>
						</Box>
						{/* Content */}
						<Box className='temp-card-content st'>
							{/* Label */}
							<Typography component='h4'>Logout</Typography>
							{/* Description */}
							<Typography component='p'>Logout of the Portal.</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}
