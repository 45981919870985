import { Box } from '@mui/material'
import TNOverview from '../../../components/shared/pageShared/TNManagement/TNOverview'

const TNManagement = ({
	customerSpecificID,
}: {
	customerSpecificID: string
}) => {
	return (
		// Display TN overview table
		<Box id='TNs'>
			<TNOverview numberOfRows={10} customerSpecificID={customerSpecificID} />
		</Box>
	)
}

export default TNManagement
