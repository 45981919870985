import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'

const ConfirmOrder = ({
	orderID,
	handleConfirmOrder,
}: {
	orderID: number
	handleConfirmOrder: (orderIDToUpdate: number) => void
}) => {
	//Constant
	const [orderIDToUse, setOrderIDToUse] = useState(0)

	//Flags
	const [open, setOpen] = useState(false)
	const [error, setError] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)

	useEffect(() => {
		if (orderID) {
			setOrderIDToUse(orderID)
		}
	}, [orderID, error])

	const handleOpenConfirmOrderModal = () => {
		setOpen(true)
	}

	const handleCloseConfirmOrderModal = () => {
		setOpen(false)
	}

	const handleAgreeButtonClick = async () => {
		if (orderIDToUse && orderIDToUse > 0 && handleConfirmOrder) {
			setBtnLoading(true)
			await handleConfirmOrder(orderIDToUse)
			handleCloseConfirmOrderModal()
		} else {
			setError(true)
		}
	}

	return (
		<>
			<PendingOutlinedIcon onClick={handleOpenConfirmOrderModal} />
			<Dialog
				open={open}
				onClose={handleCloseConfirmOrderModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{'Confirm Order'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to confirm this order.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmOrderModal}>Cancel</Button>
					<Tooltip title={'Confirm Order'}>
						<span>
							<LoadingButton
								sx={{ marginLeft: '20px' }}
								autoFocus
								disabled={error}
								loadingPosition='start'
								loading={btnLoading}
								startIcon={<SaveIcon />}
								onClick={handleAgreeButtonClick}>
								Confirm Order
							</LoadingButton>
						</span>
					</Tooltip>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ConfirmOrder
