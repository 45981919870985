import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ModalForm } from '../../inputs/ModalForm/ModalForm'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { StyledTextField } from '../../../../styles/styledComponents/inputs/StyledTextField'

const OrderSKUPriceUpdate = ({
	externalOrderID,
	partnerMRCPrice,
	partnerNRCPrice,
	handlePartnerPriceUpdate,
	isPartnerMRCClicked,
	onClose,
}: {
	partnerMRCPrice: string
	partnerNRCPrice: string
	externalOrderID: string
	handlePartnerPriceUpdate: (
		externalOrderID: string,
		priceToUpdate: string
	) => Promise<void>
	isPartnerMRCClicked: boolean
	onClose: () => void
}) => {
	// Display constant
	const [currentMRCPrice, setCurrentMRCPrice] = useState('')
	const [currentNRCPrice, setCurrentNRCPrice] = useState('')
	const [orderExternalID, setorderExternalID] = useState('')

	// Flags
	const [error, setError] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)

	useEffect(() => {
		// Set constants
		if (externalOrderID) {
			setorderExternalID(externalOrderID)
		}
		if (partnerMRCPrice) {
			setCurrentMRCPrice(partnerMRCPrice)
		}

		if (partnerNRCPrice) {
			setCurrentNRCPrice(partnerNRCPrice)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Handle functions
	const handlePartnerPriceChange = (e: { target: any }) => {
		var newValue = e.target.value
		if (newValue && newValue < 0) {
			return
		}
		setError(false)
		isPartnerMRCClicked
			? setCurrentMRCPrice(e.target.value)
			: setCurrentNRCPrice(e.target.value)
	}

	const handleButtonClick = async () => {
		// Check if something has changed
		if (isPartnerMRCClicked) {
			if (currentMRCPrice) {
				setBtnLoading(true)
				await handlePartnerPriceUpdate(orderExternalID, currentMRCPrice)
			}
		} else {
			if (currentNRCPrice) {
				setBtnLoading(true)
				await handlePartnerPriceUpdate(orderExternalID, currentNRCPrice)
			}
		}
	}

	const handleClosePartnerUpdateModal = () => {
		onClose()
	}

	return (
		<ModalForm
			icon={<SettingsApplicationsOutlinedIcon />}
			title={isPartnerMRCClicked ? `Update MRC Price` : `Update NRC Price`}
			description={`Update current ${
				isPartnerMRCClicked ? 'mrc' : 'nrc'
			} price`}
			buttonText={`Update ${isPartnerMRCClicked ? 'MRC' : 'NRC'}`}
			buttonAction={handleButtonClick}
			buttonLoading={btnLoading}
			buttonDisabled={
				(isPartnerMRCClicked && partnerMRCPrice.length === 0) ||
				(!isPartnerMRCClicked && partnerNRCPrice.length === 0)
			}
			closeModal={handleClosePartnerUpdateModal}>
			<>
				{/* Form Group - External Order ID */}
				<Box className='form-group'>
					<Typography component='p' className='form-label'>
						External Order ID
					</Typography>
					<StyledTextField
						disabled
						fullWidth
						value={orderExternalID}
						type='text'
						placeholder='External Order ID'
						style={{ fontSize: '1rem' }}
					/>
				</Box>

				{/* Form Group - External Order ID */}
				<Box className='form-group'>
					<Typography component='p' className='form-label'>
						{isPartnerMRCClicked ? `MRC` : `NRC`}
						<Typography component='span' className='required'>
							*
						</Typography>
					</Typography>
					<StyledTextField
						fullWidth
						required
						value={isPartnerMRCClicked ? currentMRCPrice : currentNRCPrice}
						error={error}
						helperText={
							error
								? `order sku ${
										isPartnerMRCClicked ? `MRC` : ` NRC`
								  } value can't be the same as previous value`
								: ``
						}
						type='number'
						placeholder='Price'
						onChange={handlePartnerPriceChange}
						onWheel={(e) => (e.target as HTMLElement).blur()}
						style={{ fontSize: '1rem' }}
					/>
				</Box>
			</>
		</ModalForm>
	)
}

export default OrderSKUPriceUpdate
