import { useState, useEffect } from 'react'
import {
	Box,
	Divider,
	Typography,
	useTheme,
	FormControlLabel,
	Checkbox,
	Autocomplete,
	AutocompleteRenderInputParams,
	Tooltip,
} from '@mui/material'
import './AddCustomer.scss'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'
import { StyledAlert } from '../../../../../styles/styledComponents/inputs/StyledAlert'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { StyledChip } from '../../../../../styles/styledComponents/inputs/StyledChip'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
	AddressTypes,
	CarrierTypes,
	ContactDetailTypes,
	Countries,
	CustomerPartnerTypes,
	CustomerStatuses,
	ReturnTypes,
} from '../../../../../utils/enums/enums'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	Address,
	AddressMap,
	ContactDetail,
	ContactDetailMap,
	Country,
	Customer,
	CustomerIDList,
	State,
} from '../../../../../utils/interfaces/DBModels'
import {
	hexToRgba,
	containsSpecialChars,
	showErrorToast,
	showSuccessToast,
	validateEmail,
	isPhoneNumberValidLength,
} from '../../../../../utils/helperFunctions/helperFunctions'
import UseAPIHandler from '../../../../../utils/customHooks/APICalls/UseAPIHandler'
const AddCustomer = ({
	reloadData,
	handleCloseAddCustomerFormModal,
}: {
	reloadData: () => void
	handleCloseAddCustomerFormModal: any
}) => {
	//Global Variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)
	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Styling of Add Customer Form
	const theme = useTheme()
	const sectionColor = hexToRgba(theme.palette.primary.main, 0.2)

	// Display constants
	// Customer
	const [customerName, setCustomerName] = useState('')
	const [customerReference, setCustomerReference] = useState('')

	// Address
	const [houseNumber, setHouseNumber] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine2, setAddressLine2] = useState('')
	const [city, setCity] = useState('')
	const [currentState, setCurrentState] = useState({} as State)
	const [currentCountry, setCurrentCountry] = useState({} as Country)
	const [stateList, setStateList] = useState([] as State[])
	const [countryList, setCountryList] = useState([] as Country[])
	const [postalCode, setPostalCode] = useState('')

	// Billing contact
	const [billingContactPersonName, setBillingContactPersonName] = useState('')
	const [billingContactPersonEmail, setBillingContactPersonEmail] = useState('')
	const [billingContactCountry, setBillingContactCountry] = useState(
		{} as Country
	)
	const [billingDialingCode, setBillingDialingCode] = useState('')
	const [billingContactPersonPhoneNumber, setBillingContactPersonPhoneNumber] =
		useState('')

	// Technical contact
	const [technicalContactPersonName, setTechnicalContactPersonName] =
		useState('')
	const [technicalContactPersonEmail, setTechnicalContactPersonEmail] =
		useState('')
	const [technicalContactCountry, setTechnicalContactCountry] = useState(
		{} as Country
	)
	const [technicalDialingCode, setTechnicalDialingCode] = useState('')
	const [
		technicalContactPersonPhoneNumber,
		setTechnicalContactPersonPhoneNumber,
	] = useState('')

	// API Flags
	const [stateCountryCallMade, setStateCountryCallMade] = useState(false)
	const [stateAndCountryDataReceived, setStateAndCountryDataReceived] =
		useState(false)
	const [isButtonLoading, setIsButtonLoading] = useState(false)

	// Form Flags
	const [customerNameError, setCustomerNameError] = useState(false)
	const [customerReferenceError, setCustomerReferenceError] = useState(false)
	const [addBillingAddress, setAddBillingAddress] = useState(false)
	const [houseNumberError, setHouseNumberError] = useState(false)
	const [addressLine1Error, setAddressLine1Error] = useState(false)
	const [addressLine2Error, setAddressLine2Error] = useState(false)
	const [cityError, setCityError] = useState(false)
	const [postalCodeError, setPostalCodeError] = useState(false)
	const [addBillingContact, setAddBillingContact] = useState(false)
	const [addTechnicalContact, setAddTechnicalContact] = useState(false)
	const [billingContactPersonNameError, setBillingContactPersonNameError] =
		useState(false)
	const [billingContactPersonEmailError, setBillingContactPersonEmailError] =
		useState(false)
	const [
		billingContactPersonPhoneNumberError,
		setBillingContactPersonPhoneNumberError,
	] = useState(false)
	const [technicalContactPersonNameError, setTechnicalContactPersonNameError] =
		useState(false)
	const [
		technicalContactPersonEmailError,
		setTechnicalContactPersonEmailError,
	] = useState(false)
	const [
		technicalContactPersonPhoneNumberError,
		setTechnicalContactPersonPhoneNumberError,
	] = useState(false)

	// Hooks
	const { fetchData, modifyData } = UseCrud()
	const { handleAPICall } = UseAPIHandler()

	useEffect(() => {
		// Get the state and countries only if user is added address or contact information
		if (!stateCountryCallMade) {
			setStateCountryCallMade(true)
			getStateAndCountryList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// *********************************************************** Handle Functions *********************************************************** //

	// Handle Customer Name Change
	const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setCustomerName(newValue)

		//Validation - Customer name cannot be empty
		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setCustomerNameError(true)
		} else {
			setCustomerNameError(false)
		}
	}

	// Handle Partner Customer Reference Change
	const handleCustomerReferenceChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setCustomerReference(newValue)

		// Validation - Customer name cannot be empty
		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setCustomerReferenceError(true)
		} else {
			setCustomerReferenceError(false)
		}
	}

	// Handle House Number Change
	const handleHouseNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setHouseNumber(e.target.value)
		//Validation - Customer name cannot be empty
		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setHouseNumberError(true)
		} else {
			setHouseNumberError(false)
		}
	}

	// Ensures that error is cleared when the user types again
	const handleAddressLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine1(newValue)

		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setAddressLine1Error(true)
		} else {
			setAddressLine1Error(false)
		}
	}

	// Handle Address Line 2 Change
	const handleAddressLine2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setAddressLine2(newValue)

		if (containsSpecialChars(newValue)) {
			setAddressLine2Error(true)
		} else {
			setAddressLine2Error(false)
		}
	}

	// Handle City Change
	const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setCity(newValue)

		if (containsSpecialChars(newValue)) {
			setCityError(true)
		} else {
			setCityError(false)
		}
	}

	// Handle Postal Code Change
	const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var newValue = e.target.value
		setPostalCode(newValue)

		if (containsSpecialChars(newValue)) {
			setPostalCodeError(true)
		} else {
			setPostalCodeError(false)
		}
	}

	// Handle name change for billing contact
	const handleBillingContactPersonNameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setBillingContactPersonName(newValue)
		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setBillingContactPersonNameError(true)
		} else {
			setBillingContactPersonNameError(false)
		}
	}

	// Handle email change for billing contact
	const handleBillingContactPersonEmailChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setBillingContactPersonEmail(newValue)
		if (newValue.trim().length === 0 || !validateEmail(newValue)) {
			setBillingContactPersonEmailError(true)
		} else {
			setBillingContactPersonEmailError(false)
		}
	}

	// State autcomplete dropdown change
	const handleStateChange = (newValue: any) => {
		var stateObj = newValue as State
		setCurrentState(stateObj)
	}

	// Country autocomplete dropdown change
	const handleCountryChange = (newValue: any) => {
		var countryObj = newValue as Country
		setCurrentCountry(countryObj)

		if (countryObj.CountryID) {
			// Get first state object
			var firstState = stateList.find(
				(s) => Number(s.CountryID) === Number(countryObj.CountryID)
			)
			if (firstState) {
				setCurrentState(firstState)
			}
		}
	}

	// Handle country change for billing contact
	const handleBillingContactCountryChange = (newValue: any) => {
		var countryObj = newValue as Country
		if (countryObj.CountryDiallingCode) {
			setBillingDialingCode(countryObj.CountryDiallingCode)
		}
		setBillingContactCountry(countryObj)
	}

	// Handle phone number change for billing contact
	const handleBillingContactPersonPhoneNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value

		setBillingContactPersonPhoneNumber(newValue)

		//check for special characters  or length to send back correct error message
		if (
			newValue.length > 0 &&
			(containsSpecialChars(newValue) ||
				newValue.includes('e') ||
				!isPhoneNumberValidLength(newValue) ||
				isNaN(Number(newValue)))
		) {
			setBillingContactPersonPhoneNumberError(true)
		} else {
			setBillingContactPersonPhoneNumberError(false)
		}
	}

	// Handle name change for technical contact
	const handleTechnicalContactPersonNameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setTechnicalContactPersonName(newValue)

		if (newValue.trim().length === 0 || containsSpecialChars(newValue)) {
			setTechnicalContactPersonNameError(true)
		} else {
			setTechnicalContactPersonNameError(false)
		}
	}

	// Handle email change for technical contact
	const handleTechnicalContactPersonEmailChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value
		setTechnicalContactPersonEmail(newValue)

		if (newValue.trim().length === 0 || !validateEmail(newValue)) {
			setTechnicalContactPersonEmailError(true)
		} else {
			setTechnicalContactPersonEmailError(false)
		}
	}

	// Handle country change for technical contact
	const handleTechnicalContactCountryChange = (newValue: any) => {
		var countryObj = newValue as Country
		if (countryObj.CountryDiallingCode) {
			setTechnicalDialingCode(countryObj.CountryDiallingCode)
		}
		setTechnicalContactCountry(countryObj)
	}

	// Handle phone number change for technical contact
	const handleTechnicalContactPersonPhoneNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		var newValue = e.target.value

		setTechnicalContactPersonPhoneNumber(newValue)

		//check for special characters  or length to send back correct error message
		if (
			newValue.length > 0 &&
			(containsSpecialChars(newValue) ||
				newValue.includes('e') ||
				!isPhoneNumberValidLength(newValue))
		) {
			setTechnicalContactPersonPhoneNumberError(true)
		} else {
			setTechnicalContactPersonPhoneNumberError(false)
		}
	}

	// Validate field based on function (special char and email, phone functions)
	const validateField = (
		value: string,
		validationFn: (val: string) => boolean,
		isRequired: boolean = false
	) => {
		if (!value && !isRequired) return true // Ignore empty non-required fields
		return validationFn(value)
	}

	// Validate entire form
	const validateForm = () => {
		// Define all your validation conditions
		const validations = [
			validateField(customerName, (val) => !containsSpecialChars(val), true),
			validateField(
				customerReference,
				(val) => !containsSpecialChars(val),
				true
			),
			validateField(houseNumber, (val) => !containsSpecialChars(val)),
			validateField(addressLine1, (val) => !containsSpecialChars(val), true),
			validateField(addressLine2, (val) => !containsSpecialChars(val)),
			validateField(city, (val) => !containsSpecialChars(val), true),
			validateField(postalCode, (val) => !containsSpecialChars(val)),
			validateField(
				billingContactPersonName,
				(val) => !containsSpecialChars(val),
				true
			),
			validateField(billingContactPersonEmail, validateEmail, true),
			validateField(
				billingContactPersonPhoneNumber,
				(val) => !containsSpecialChars(val) && isPhoneNumberValidLength(val)
			),
		]

		// Check Technical contact if ticked
		if (addTechnicalContact) {
			validations.push(
				validateField(
					technicalContactPersonName,
					(val) => !containsSpecialChars(val),
					true
				),
				validateField(technicalContactPersonEmail, validateEmail, true),
				validateField(
					technicalContactPersonPhoneNumber,
					(val) => !containsSpecialChars(val) && isPhoneNumberValidLength(val)
				)
			)
		}

		// Loop and validate all fields
		return validations.every(Boolean)
	}

	// Error flags
	const hasErrorFlags = () => {
		// Check if there are any error flags
		return (
			customerNameError ||
			customerReferenceError ||
			houseNumberError ||
			addressLine1Error ||
			addressLine2Error ||
			cityError ||
			postalCodeError ||
			billingContactPersonNameError ||
			billingContactPersonEmailError ||
			billingContactPersonPhoneNumberError ||
			(addTechnicalContact &&
				(technicalContactPersonNameError ||
					technicalContactPersonEmailError ||
					technicalContactPersonPhoneNumberError))
		)
	}

	// Disable button
	const disableAddCustomerButton = () => {
		// Disable the button if the form is invalid or if there are error flags
		return !validateForm() || hasErrorFlags()
	}

	// *********************************************************** API Calls *********************************************************** //

	// GET: return country and state
	const getStateAndCountryList = async () => {
		//Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'AddCustomer.tsx: getStateAndCountryList()',
			QueryURL: `GetV2?Params=State.Where(State.IsActive='true'),Country.Where(Country.IsActive='true')`,
			ErrorMessage:
				'An error occurred when retrieving State and Country information',
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var stateList = dataResponse.Obj.StateList as State[]
			var countryList = dataResponse.Obj.CountryList as Country[]

			var uk = countryList.find((c) => Number(c.CountryID) === Countries.UK)
			var firstState = stateList.find(
				(s) => Number(s.CountryID) === Countries.UK
			)
			if (uk) {
				setCurrentCountry(uk)

				// Contact country
				setBillingContactCountry(uk)
				setTechnicalContactCountry(uk)

				// Dialing code for contact
				if (uk.CountryDiallingCode) {
					setBillingDialingCode(uk.CountryDiallingCode)
					setTechnicalDialingCode(uk.CountryDiallingCode)
				}
			}
			if (firstState) {
				setCurrentState(firstState)
			}
			setStateList(stateList)
			setCountryList(countryList)

			// Stop loading
			setStateAndCountryDataReceived(true)
		} else {
			showErrorToast('An error occurred when getting state and country.')
		}
	}

	// GET: Return back the latest available customerID
	const getLatestCustomerID = async () => {
		// Return obj
		var customerID = ''

		// Make API Call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'AddCustomer.tsx: getLatestCustomerID()',
			QueryURL: `GetV2?Params=CustomerIDList.First(CustomerIDList.IsAvailable = '1')`,
			ErrorMessage: 'An error occurred when getting Latest Customer ID',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerIDResponseObj = dataResponse.Obj
				.CustomerIDList as CustomerIDList

			if (
				customerIDResponseObj &&
				customerIDResponseObj.CustomerID &&
				customerIDResponseObj.CustomerID.length > 0
			) {
				// Update it so it is not available anymore
				customerIDResponseObj.IsAvailable = false

				// Update obj
				var updateCustomerIDObj = {
					CustomerIDList: customerIDResponseObj,
				}

				var customerIDModified = (await modifyData({
					FileAndFunctionName: 'AddCustomer.tsx: getLatestCustomerID()',
					QueryURL: `UpdateV2?Params=CustomerIDList`,
					ErrorMessage:
						'An error occurred when updating the customer ID to unavailable',
					QueryObj: updateCustomerIDObj,
					LogErrorToDB: true,
					UserName: loggedInUser.email,
					SuccessMessage: 'Successfully Updated CustomerID',
					ShowErrorMessage: false,
					ShowSuccessMessage: false,
				})) as Boolean

				if (customerIDModified) {
					customerID = customerIDResponseObj.CustomerID
				}
			}
		}

		// Return obj
		return customerID
	}

	//Add Customer, Address, Billing and Technical to DB
	const addCustomerInfo = async (customerID: string) => {
		var addCustomerInfoObj

		var customerObj: Customer = {
			CustomerID: customerID.trim(),
			CustomerName: customerName.trim(),
			CustomerPartnerID: partnerID,
			CustomerStatusID: CustomerStatuses.Active,
			CustomerPartnerReference: customerReference.trim(),
		}

		var addressObj: Address = {
			HouseNumber: houseNumber.trim(),
			AddressLine1: addressLine1.trim(),
			AddressLine2: addressLine2.trim(),
			City: city.trim(),
			StateID: currentState.StateID,
			AddressTypeID: AddressTypes.Billing,
			PostalCode: postalCode.trim(),
		}

		var billingContactObj = {
			ContactDetailTypeID: ContactDetailTypes.Billing,
			ContactPersonName: billingContactPersonName.trim(),
			ContactPersonEmail: billingContactPersonEmail.trim(),
			ContactPersonPhoneNumber: `${billingDialingCode}${billingContactPersonPhoneNumber.trim()}`,
		}

		if (addTechnicalContact) {
			var technicalContactObj = {
				ContactDetailTypeID: ContactDetailTypes.Engineering,
				ContactPersonName: technicalContactPersonName.trim(),
				ContactPersonEmail: technicalContactPersonEmail.trim(),
				ContactPersonPhoneNumber: `${technicalDialingCode}${technicalContactPersonPhoneNumber.trim()}`,
			}

			addCustomerInfoObj = {
				Customer: customerObj,
				Address: addressObj,
				ContactDetailList: [billingContactObj, technicalContactObj],
			}
		} else {
			addCustomerInfoObj = {
				Customer: customerObj,
				Address: addressObj,
				ContactDetailList: [billingContactObj],
			}
		}

		// Make post call with new API handler that returns object with ID
		var dataResponse = await handleAPICall<DataResponse>({
			Action: 'Add Address',
			RequestURL: `AddV2?Params=Customer,Address,ContactDetail:list`,
			RequestObj: addCustomerInfoObj,
			IsMutations: true,
			ShowSuccessMessage: false,
			ShowErrorMessage: false,
			ErrorMessage: 'An error occurred when adding a customer',
			LogErrorToDB: true,
		})
		if (dataResponse && dataResponse.Obj) {
			var addressIDReturned = 0
			var billingContactIDReturned = 0
			var technicalContactIDReturned = 0

			if (dataResponse && dataResponse.Obj) {
				var addressDataResponse = dataResponse.Obj.Address as Address

				var billingContactDataResponse = dataResponse.Obj
					.ContactDetailList[0] as ContactDetail

				if (
					addressDataResponse &&
					addressDataResponse.AddressID &&
					addressDataResponse.AddressID > 0
				) {
					addressIDReturned = addressDataResponse.AddressID
				}

				if (
					billingContactDataResponse &&
					billingContactDataResponse.ContactDetailID &&
					billingContactDataResponse.ContactDetailID > 0
				) {
					billingContactIDReturned = billingContactDataResponse.ContactDetailID
				}

				if (addTechnicalContact) {
					var technicalContactDataResponse = dataResponse.Obj
						.ContactDetailList[1] as ContactDetail

					if (
						technicalContactDataResponse &&
						technicalContactDataResponse.ContactDetailID &&
						technicalContactDataResponse.ContactDetailID > 0
					) {
						technicalContactIDReturned =
							technicalContactDataResponse.ContactDetailID
					}
				}
			}

			return {
				customerDetailsAdded: true,
				addressID: addressIDReturned,
				billingContactID: billingContactIDReturned,
				technicalContactID: technicalContactIDReturned,
			}
		} else {
			return {
				customerDetailsAdded: false,
			}
		}
	}

	//Mapping Address, Billing and Technical contacts
	const mapAddressAndContacts = async (
		customerID: string,
		addressID: number,
		billingContactID: number,
		technicalContactID?: number
	) => {
		var isAddressandContactDetailMapped = false

		// Use address ID and customer ID and map to Address Map
		var addressMapObj: AddressMap = {
			AddressID: addressID,
			CustomerID: customerID,
			CarrierID: CarrierTypes.No_Carrier,
		}

		//CarrierID and CustomerPartnerID should be No Carrier and No Customer for adding Customer
		var contactBillingDetailMapObj: ContactDetailMap = {
			ContactDetailID: billingContactID,
			CustomerID: customerID,
			CarrierID: CarrierTypes.No_Carrier,
			CustomerPartnerID: CustomerPartnerTypes.No_Partner,
		}

		//check to include tech details and post to db
		var addMapObj

		if (addTechnicalContact) {
			//CarrierID and CustomerPartnerID should be No Carrier and No Customer for adding Customer
			var contactTechnicalDetailMapObj: ContactDetailMap = {
				ContactDetailID: technicalContactID,
				CustomerID: customerID,
				CarrierID: 1,
				CustomerPartnerID: 1,
			}

			addMapObj = {
				AddressMap: addressMapObj,
				ContactDetailMapList: [
					contactBillingDetailMapObj,
					contactTechnicalDetailMapObj,
				],
			}
		} else {
			addMapObj = {
				AddressMap: addressMapObj,
				ContactDetailMapList: [contactBillingDetailMapObj],
			}
		}

		// Post to DB
		var isMappedDetailsAddedToDatabase = await modifyData({
			FileAndFunctionName: 'AddCustomer.tsx: addCustomerContact() Add map',
			QueryURL: `AddV2?Params=AddressMap,ContactDetailMap:list`,
			ErrorMessage: `An error occurred when mapping detail for ${customerID}`,
			QueryObj: addMapObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: `Successfully mapped detail for ${customerID}`,
			ShowErrorMessage: false,
			ShowSuccessMessage: false,
		})

		if (isMappedDetailsAddedToDatabase) {
			isAddressandContactDetailMapped = true
		} else {
			isAddressandContactDetailMapped = false
		}

		return isAddressandContactDetailMapped
	}

	// POST: Add New Customer
	const addNewCustomer = async () => {
		//validate fields to enable Add Customer Button

		var isFormValid = validateForm()

		if (!isFormValid) {
			showErrorToast(
				'Please ensure that no fields contain any special characters and that all email addresses are valid'
			)
		} else {
			// enable show Loading Button
			setIsButtonLoading(true)

			var addressAndContactMapsAddedSuccessfully = false

			// First get the latest available customerID
			var customerID = await getLatestCustomerID()

			if (customerID && customerID.length > 0) {
				// Make API Calls to Add Customer, Billing Address and Billing Contact - Objects to build. Returns Success boolean and Objects Added
				var isCustomerInfoAdded = await addCustomerInfo(customerID)

				if (isCustomerInfoAdded?.customerDetailsAdded) {
					// map address and contacts

					if (addTechnicalContact) {
						addressAndContactMapsAddedSuccessfully =
							await mapAddressAndContacts(
								customerID,
								Number(isCustomerInfoAdded.addressID),
								Number(isCustomerInfoAdded.billingContactID),
								Number(isCustomerInfoAdded.technicalContactID)
							)
					} else {
						addressAndContactMapsAddedSuccessfully =
							await mapAddressAndContacts(
								customerID,
								Number(isCustomerInfoAdded.addressID),
								Number(isCustomerInfoAdded.billingContactID)
							)
					}

					if (addressAndContactMapsAddedSuccessfully) {
						showSuccessToast('Customer has been added successfully.')
					} else {
						showErrorToast('An error occurred when adding the customer.')
					}
				} else {
					showErrorToast('An error occurred when adding the customer.')
				}
			} else {
				showErrorToast('An error occurred when adding the customer.')
			}
			reloadData()
			handleCloseAddCustomerFormModal()
			// End Loading
			setIsButtonLoading(false)
		}
	}

	// *********************************************************** END API Calls *********************************************************** //

	return (
		<>
			<Box className='customer-form-container'>
				{/* Heading */}
				<Box className='customer-form-header'>
					<PersonAddAlt1Icon
						sx={{ color: theme.palette.primary.main, fontSize: '50px' }}
					/>
					<Box className='customer-form-header-text'>
						<Typography component='h1' style={{ fontSize: '25px' }}>
							Add new customer
						</Typography>
						<Typography component='p'>
							You can add a new customer here:
						</Typography>
					</Box>
				</Box>
				<Divider
					className='customer-form-divider'
					sx={{
						borderColor: sectionColor,
					}}
				/>
				{/* Customer Details */}
				<Box className='customer-form-customer-details'>
					<Typography variant='h6' style={{ whiteSpace: 'nowrap' }}>
						Customer Details:
					</Typography>
					<StyledAlert severity='info' style={{ padding: '0' }}>
						Please fill in the necessary customer details below. Customer Name
						is important as it will be pulled through to Invoice. Please enter
						the official company name.
					</StyledAlert>
					{/* Customer Name */}
					<Box className='add-customer-form-input'>
						<Typography component='p'>
							Customer name:{' '}
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
					</Box>
					<StyledTextField
						fullWidth
						required
						error={customerNameError}
						helperText={
							customerNameError &&
							'Customer Name is required and no special characters allowed'
						}
						value={customerName}
						onChange={handleCustomerNameChange}
						type='text'
						placeholder='Please enter a Customer Name'
					/>
					{/* Partner Customer Reference */}
					<Box className='add-customer-form-input'>
						<Typography component='p'>
							Partner customer reference:{' '}
							<Typography component='span' className='required'>
								*
							</Typography>
						</Typography>
					</Box>
					<StyledTextField
						fullWidth
						required
						error={customerReferenceError}
						helperText={
							customerReferenceError &&
							'Partner Customer Reference is required and no special characters allowed'
						}
						value={customerReference}
						onChange={handleCustomerReferenceChange}
						type='text'
						placeholder='Please enter a Partner Customer Reference'
					/>
				</Box>
				<Divider
					className='customer-form-divider'
					sx={{
						borderColor: sectionColor,
					}}
				/>
				{/* Billing Details */}
				<Box className='customer-form-customer-details'>
					<Typography variant='h6' style={{ whiteSpace: 'nowrap' }}>
						Billing details:
					</Typography>
					<StyledAlert severity='info' style={{ padding: '0' }}>
						Please note the required and optional sections.
					</StyledAlert>
				</Box>
				{/* Billing Address */}
				<Box
					p={2}
					mb={2}
					sx={{
						border: 1,
						borderColor: sectionColor,
						borderRadius: '4px',
					}}>
					{/* Billing Address Checkbox */}
					<Box className='customer-form-checkbox-container'>
						<FormControlLabel
							label='Billing address'
							control={
								<Checkbox
									checked={addBillingAddress}
									icon={<CircleOutlinedIcon />}
									checkedIcon={<CheckCircleIcon />}
									onChange={(e) => setAddBillingAddress(e.target.checked)}
								/>
							}
						/>
						{/* Styled Chip */}
						<StyledChip label='Required' color='info' variant='outlined' />
					</Box>
					{/* Billing Address Form*/}
					{addBillingAddress ? (
						<Box>
							{stateAndCountryDataReceived ? (
								<Box className='customer-form-customer-details'>
									<Divider
										className='customer-form-heading-divider'
										sx={{
											borderColor: sectionColor,
										}}
									/>
									{/* House Number */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>House number:</Typography>
									</Box>
									<StyledTextField
										fullWidth
										error={houseNumberError}
										helperText={
											houseNumberError && 'No special characters allowed'
										}
										value={houseNumber}
										onChange={handleHouseNumberChange}
										type='text'
										placeholder='House number'
									/>
									{/* Address Line 1 */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Address line 1{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<StyledTextField
										fullWidth
										required
										error={addressLine1Error}
										helperText={
											addressLine1Error
												? 'Address line 1 is required and no special characters allowed'
												: ''
										}
										value={addressLine1}
										onChange={handleAddressLine1Change}
										type='text'
										placeholder='Address line 1'
									/>
									{/* Address Line 2 */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>Address line 2</Typography>
									</Box>
									<StyledTextField
										fullWidth
										error={addressLine2Error}
										helperText={
											addressLine2Error ? 'No special characters allowed' : ''
										}
										value={addressLine2}
										type='text'
										placeholder='Address line 2'
										onChange={handleAddressLine2Change}
									/>
									{/* City */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>City</Typography>
									</Box>
									<StyledTextField
										fullWidth
										error={cityError}
										helperText={
											cityError ? 'No special characters allowed' : ''
										}
										value={city}
										type='text'
										placeholder='City'
										onChange={handleCityChange}
									/>
									{/* Country Dropdown */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Country{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<Autocomplete
										autoHighlight
										value={currentCountry || {}}
										noOptionsText='No countries found'
										options={countryList}
										getOptionLabel={(option: any) => option.CountryName}
										renderOption={(props, option: any) => (
											<Box
												value={option.CountryID}
												component='li'
												color='text.header'
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}>
												{option.CountryFlagImageURL &&
												option.CountryFlagImageURL.length > 0 ? (
													<img
														loading='lazy'
														width='20'
														src={option.CountryFlagImageURL}
														srcSet={option.CountryFlagImageURL}
														alt='Country Flag'
													/>
												) : (
													<img
														loading='lazy'
														width='20'
														src={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														srcSet={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														alt='Country Flag Placeholder'
													/>
												)}
												{option.CountryName + ''}
											</Box>
										)}
										renderInput={(params: AutocompleteRenderInputParams) => (
											<StyledTextField {...params} label='Country' />
										)}
										isOptionEqualToValue={(option: any, value: any) =>
											option.CountryName === value.CountryName
										}
										onChange={(e: any, newValue: any) => {
											handleCountryChange(newValue)
										}}
									/>
									{/* State Dropdown */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											State{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<Autocomplete
										autoHighlight
										value={currentState || {}}
										noOptionsText='No states found for selected country'
										options={stateList.filter(
											(s) =>
												Number(s.CountryID) === Number(currentCountry.CountryID)
										)}
										getOptionLabel={(option: any) => option.StateName}
										renderOption={(props, option: any) => (
											<Box
												value={option.StateID}
												component='li'
												color='text.header'
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}>
												{option.StateName + ''}
											</Box>
										)}
										renderInput={(params: AutocompleteRenderInputParams) => (
											<StyledTextField {...params} label='State' />
										)}
										isOptionEqualToValue={(option: any, value: any) =>
											option.StateName === value.StateName
										}
										onChange={(e: any, newValue: any) => {
											handleStateChange(newValue)
										}}
									/>
									{/* Postal Code */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>Postal code</Typography>
									</Box>
									<StyledTextField
										fullWidth
										error={postalCodeError}
										helperText={
											postalCodeError ? 'No special characters allowed' : ''
										}
										value={postalCode}
										type='text'
										placeholder='Postal code'
										onChange={handlePostalCodeChange}
									/>
								</Box>
							) : (
								<Typography>
									Please wait as we load your relevant data
								</Typography>
							)}
						</Box>
					) : null}
				</Box>

				{/* End of billing address details  */}
				{/* Billing Contact*/}
				<Box
					p={2}
					mb={2}
					sx={{
						border: 1,
						borderColor: sectionColor,
						borderRadius: '4px',
					}}>
					{/* Billing Contact Checkbox */}
					<Box className='customer-form-checkbox-container'>
						<FormControlLabel
							label='Billing contact details'
							control={
								<Checkbox
									checked={addBillingContact}
									icon={<CircleOutlinedIcon />}
									checkedIcon={<CheckCircleIcon />}
									onChange={(e) => setAddBillingContact(e.target.checked)}
								/>
							}
						/>
						{/* Styled Chip */}
						<StyledChip label='Required' color='info' variant='outlined' />
					</Box>
					{/* Billing Contact Form*/}
					{addBillingContact ? (
						<Box className='customer-form-opt-container'>
							{stateAndCountryDataReceived ? (
								<Box className='customer-form-customer-details'>
									<Divider
										className='customer-form-heading-divider'
										sx={{
											borderColor: sectionColor,
										}}
									/>
									{/* Contact Person Name */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Billing contact person name{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<StyledTextField
										fullWidth
										required
										error={billingContactPersonNameError}
										helperText={
											billingContactPersonNameError
												? 'Billing contact person name is required and no special characters allowed'
												: ''
										}
										value={billingContactPersonName}
										type='text'
										placeholder='Contact person name'
										onChange={handleBillingContactPersonNameChange}
									/>
									{/* Contact Person Email */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Billing contact person email{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<StyledTextField
										fullWidth
										required
										value={billingContactPersonEmail}
										error={billingContactPersonEmailError}
										helperText={
											billingContactPersonEmailError
												? 'Billing contact person email is required and needs to be a valid email format'
												: ''
										}
										type='text'
										placeholder='Contact person email'
										onChange={handleBillingContactPersonEmailChange}
									/>
									{/* Country Dropdown - Used to get dialing code */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Billing contact person location
										</Typography>
									</Box>
									<Autocomplete
										autoHighlight
										value={billingContactCountry || {}}
										noOptionsText='No countries found'
										options={countryList}
										getOptionLabel={(option: any) => option.CountryName}
										renderOption={(props, option: any) => (
											<Box
												value={option.CountryID}
												component='li'
												color='text.header'
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}>
												{option.CountryFlagImageURL &&
												option.CountryFlagImageURL.length > 0 ? (
													<img
														loading='lazy'
														width='20'
														src={option.CountryFlagImageURL}
														srcSet={option.CountryFlagImageURL}
														alt='Country Flag'
													/>
												) : (
													<img
														loading='lazy'
														width='20'
														src={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														srcSet={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														alt='Country Flag Placeholder'
													/>
												)}
												{option.CountryName + ''}
											</Box>
										)}
										renderInput={(params: AutocompleteRenderInputParams) => (
											<StyledTextField {...params} label='Country' />
										)}
										isOptionEqualToValue={(option: any, value: any) =>
											option.CountryName === value.CountryName
										}
										onChange={(e: any, newValue: any) => {
											handleBillingContactCountryChange(newValue)
										}}
									/>
									{/* Contact Person Phone Number */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Billing contact person phone number
										</Typography>
									</Box>
									<Box className='phone-number'>
										<StyledTextField
											disabled
											sx={{ width: '100px' }}
											value={billingDialingCode}
											type='text'
											placeholder='Code'
										/>
										<StyledTextField
											onWheel={(e) => (e.target as HTMLElement).blur()}
											fullWidth
											value={billingContactPersonPhoneNumber}
											error={billingContactPersonPhoneNumberError}
											helperText={
												billingContactPersonPhoneNumberError
													? 'Number has an invalid length or contains non numeric characters'
													: ''
											}
											inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
											placeholder='Contact person phone number'
											onChange={handleBillingContactPersonPhoneNumberChange}
										/>
									</Box>
								</Box>
							) : (
								<Typography>
									Please wait as we load your relevant data
								</Typography>
							)}
						</Box>
					) : null}
				</Box>
				{/* End of billing contact details  */}
				<Box
					p={2}
					mb={2}
					sx={{
						border: 1,
						borderColor: sectionColor,
						borderRadius: '4px',
					}}>
					{/* Technical Contact Checkbox */}
					<Box className='customer-form-checkbox-container'>
						<FormControlLabel
							label='Technical contact details'
							control={
								<Checkbox
									checked={addTechnicalContact}
									icon={<CircleOutlinedIcon />}
									checkedIcon={<CheckCircleIcon />}
									onChange={(e) => setAddTechnicalContact(e.target.checked)}
								/>
							}
						/>
						{/* Styled Chip */}
						<StyledChip label='Optional' color='info' variant='outlined' />
					</Box>
					{/* Technical Contact Form */}
					{addTechnicalContact ? (
						<Box className='customer-form-opt-container'>
							{stateAndCountryDataReceived ? (
								<Box className='customer-form-customer-details'>
									<Divider
										className='customer-form-heading-divider'
										sx={{
											borderColor: sectionColor,
										}}
									/>
									{/* Contact Person Name */}
									<Box className='add-customer-form-input'>
										<Typography component='p'>
											Technical contact person name{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<StyledTextField
										fullWidth
										value={technicalContactPersonName}
										type='text'
										placeholder='Contact person name'
										required
										error={technicalContactPersonNameError}
										helperText={
											technicalContactPersonNameError
												? 'Technical contact person name is required and no special characters allowed'
												: ''
										}
										onChange={handleTechnicalContactPersonNameChange}
									/>
									{/* Contact Person Email */}
									<Box className='customer-form-input'>
										<Typography component='p'>
											Technical contact person email{' '}
											<Typography component='span' className='required'>
												*
											</Typography>
										</Typography>
									</Box>
									<StyledTextField
										fullWidth
										value={technicalContactPersonEmail}
										type='text'
										placeholder='Contact person email'
										required
										error={technicalContactPersonEmailError}
										helperText={
											technicalContactPersonEmailError
												? 'Technical contact person email is required and needs to be a valid email format'
												: ''
										}
										onChange={handleTechnicalContactPersonEmailChange}
									/>
									{/* Country Dropdown - Used to get dialing code */}
									<Box className='customer-form-input'>
										<Typography component='p'>
											Technical contact person location
										</Typography>
									</Box>
									<Autocomplete
										autoHighlight
										value={technicalContactCountry || {}}
										noOptionsText='No countries found'
										options={countryList}
										getOptionLabel={(option: any) => option.CountryName}
										renderOption={(props, option: any) => (
											<Box
												value={option.CountryID}
												component='li'
												color='text.header'
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}>
												{option.CountryFlagImageURL &&
												option.CountryFlagImageURL.length > 0 ? (
													<img
														loading='lazy'
														width='20'
														src={option.CountryFlagImageURL}
														srcSet={option.CountryFlagImageURL}
														alt='Country Flag'
													/>
												) : (
													<img
														loading='lazy'
														width='20'
														src={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														srcSet={require('../../../../../assets/images/placeHolder/flag-placeholder.png')}
														alt='Country Flag Placeholder'
													/>
												)}
												{option.CountryName + ''}
											</Box>
										)}
										renderInput={(params: AutocompleteRenderInputParams) => (
											<StyledTextField {...params} label='Country' />
										)}
										isOptionEqualToValue={(option: any, value: any) =>
											option.CountryName === value.CountryName
										}
										onChange={(e: any, newValue: any) => {
											handleTechnicalContactCountryChange(newValue)
										}}
									/>
									{/* Contact Person Phone Number */}
									<Box className='customer-form-input'>
										<Typography component='p'>
											Technical contact person phone number
										</Typography>
									</Box>
									<Box className='phone-number'>
										<StyledTextField
											disabled
											sx={{ width: '100px' }}
											value={technicalDialingCode}
											type='text'
											placeholder='Code'
										/>
										<StyledTextField
											onWheel={(e) => (e.target as HTMLElement).blur()}
											fullWidth
											value={technicalContactPersonPhoneNumber}
											error={technicalContactPersonPhoneNumberError}
											helperText={
												technicalContactPersonPhoneNumberError
													? 'Number has an invalid length or contains non numeric character'
													: ''
											}
											inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
											placeholder='Contact person phone number'
											onChange={handleTechnicalContactPersonPhoneNumberChange}
										/>
									</Box>
								</Box>
							) : (
								<Typography>
									Please wait as we load your relevant data
								</Typography>
							)}
						</Box>
					) : null}
				</Box>
				{/* End of technical contact details  */}
				<Divider
					className='customer-form-divider'
					sx={{
						borderColor: sectionColor,
					}}
				/>
				<Box className='add-customer-button-container'>
					<Box className='customer-form-button'>
						<Tooltip
							title={
								disableAddCustomerButton()
									? 'Please ensure all the required fields are filled in'
									: 'Add Customer'
							}>
							<span>
								<StyledLoadingButton
									disabled={disableAddCustomerButton()}
									loading={isButtonLoading}
									onClick={addNewCustomer}>
									Add new Customer
								</StyledLoadingButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default AddCustomer
