import './CustomerDashboard.scss'
import { Box } from '@mui/material'
import { CustomerDashboardCards } from './CustomerDashboardCards/CustomerDashboardCards'
import { useEffect } from 'react'

export const CustomerDashboard = () => {
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box className='temp-container'>
				<CustomerDashboardCards />
			</Box>
		</>
	)
}
