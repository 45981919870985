import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useEffect, useState } from 'react'
import {
	Currencies,
	OrderSKUStatuses,
	OrderStatuses,
	OrderTypes,
	PricingTypes,
	ReturnTypes,
	Roles,
} from '../../../../utils/enums/enums'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import {
	Customer,
	Order,
	OrderSKU,
	OrderSKUTerm,
	SKUPartnerMap,
} from '../../../../utils/interfaces/DBModels'
import { extractPropertyByID } from '../../../../utils/helperFunctions/helperFunctions'
import {
	OrderSKUPriceDisplay,
	OrdersDisplay,
} from '../../../../utils/interfaces/ComponentModels'
import OrdersOverviewDisplay from './OrdersOverviewDisplay/OrdersOverviewDisplay'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import './OrdersOverview.scss'
import { ErrorDisplay } from '../../displays/ErrorDisplay/ErrorDisplay'

const OrdersOverview = ({
	numberOfRows,
	customerSpecificID,
}: {
	numberOfRows: number
	customerSpecificID?: string
}) => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Hooks
	const { fetchData } = UseCrud()

	// Flags
	const [orderCallMade, setOrderCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [customerID, setCustomerID] = useState('')

	// Display constants
	const [ordersDisplay, setOrdersDisplay] = useState([] as OrdersDisplay[])

	// Error display
	const [errorTitle, setErrorTitle] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	// Arrays
	const [orderList, setOrderList] = useState([] as Order[])
	const [customerList, setCustomerList] = useState([] as Customer[])
	const [skuPartnerMapList, setSkuPartnerMapList] = useState([] as any[])
	const [orderSKUList, setOrderSKUList] = useState([] as OrderSKU[])
	const [standardNoRangeNoTermSKUs, setStandardNoRangeNoTermSKUs] = useState(
		[] as SKUPartnerMap[]
	)

	// 1 - Controls customer ID change to reload component on customer overview
	useEffect(() => {
		if (customerSpecificID && customerSpecificID !== customerID) {
			resetFlagsAndArrays()
			setCustomerID(customerSpecificID)
		} else {
			// Make order call
			handleOrderCall()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerSpecificID, customerID, orderCallMade, apiCallsDone])

	// Reset flags and array - Mainly for when the customerID changes
	const resetFlagsAndArrays = () => {
		// Flags
		setOrderCallMade(false)
		setAPICallsDone(false)
		// Arrays
		setOrdersDisplay([])
		setOrderList([])
		setCustomerList([])
		setSkuPartnerMapList([])
		setOrderSKUList([])
	}

	// Handle call to get orders
	const handleOrderCall = () => {
		if (errorMessage) return

		if (!orderCallMade) {
			setOrderCallMade(true)
			getOrders()
		}
	}

	// Validation to make call
	const validateCall = () => {
		let isValid = true

		if (roleID === Roles.CustomerAdmin) {
			if (!loggedInUser.customerID) {
				isValid = false
				setError(
					'No customer ID assigned',
					'Please contact support for assistance.'
				)
			} else if (!checkIfCustomerExists()) {
				isValid = false
				setError(
					'Invalid customer ID assigned',
					'Please contact support for assistance.'
				)
			}
		}

		return isValid
	}

	// Get request URL based on specific parameters
	const getRequestURL = () => {
		let requestURL = ''

		if (customerSpecificID && customerID && customerID.length > 0) {
			requestURL = `GetV2?Params=Order.OrderSKU.OrderSKUTerm.OrderSKUProductMap.Product.Includes(Order.CustomerID = '${customerID}' & Order.ExternalOrderID ~ 'ORD'), Customer.Where(Customer.CustomerID = '${customerID}')')`
		} else if (!customerSpecificID) {
			switch (roleID) {
				case Roles.CustomerAdmin:
					requestURL = `GetV2?Params=Order.OrderSKU.OrderSKUTerm.OrderSKUProductMap.Product.Includes(Order.CustomerID = '${loggedInUser.customerID}' & Order.ExternalOrderID ~ 'ORD'), Customer.Where(Customer.CustomerID = '${loggedInUser.customerID}')`
					break
				case Roles.PartnerAdmin:
					requestURL = `GetV2?Params=Order.OrderSKU.OrderSKUTerm.OrderSKUProductMap.Product.Customer.CustomerPartner.Includes(Customer.CustomerPartnerID= '${partnerID}' & Order.ExternalOrderID ~ 'ORD'), Customer.Where(Customer.CustomerPartnerID= '${partnerID}'), SKUPartnerMap.PriceBook.Where(SKUPartnerMap.CustomerPartnerID = '${partnerID}' & SKUPartnerMap.SKUTypeID !~ 'POC')`
					break
				default:
					break
			}
		}

		return requestURL
	}

	// Handle set of error
	const setError = (title: string, message: string) => {
		setErrorTitle(title)
		setErrorMessage(message)
	}

	// *** API Calls *** //
	// GET: Validate that the customerID exists
	const checkIfCustomerExists = async () => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'OrdersOverview.tsx: getOrders()',
			QueryURL: `GetV2?Params=Customer.Exists(Customer.CustomerID = '${loggedInUser.customerID}')`,
			ErrorMessage: 'An error occurred when getting order information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0) {
			return true
		}

		return false
	}

	// GET: Return orders for customer or partner depending on role and build display array
	const getOrders = async () => {
		try {
			// Check if all is valid
			const isValid = validateCall()

			if (!isValid) return

			// Get URL
			const requestURL = getRequestURL()
			// Make call based on request URL
			var dataResponse = (await fetchData({
				FileAndFunctionName: 'OrdersOverview.tsx: getOrders()',
				QueryURL: requestURL,
				ErrorMessage: 'An error occurred when getting order information',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				// *** Get the all relevant lists and build the display array *** //
				var orderList = dataResponse.Obj.OrderList as Order[]
				var customerList = dataResponse.Obj.CustomerList as Customer[]
				var skuPartnerMapList = dataResponse.Obj
					.SKUPartnerMapList as SKUPartnerMap[]
				var { orderSKUTermList, orderSKUList } = extractSKUsAndTerms(orderList)

				// *** Build the relevant display array *** //
				if (
					(orderList && orderList.length > 0) ||
					(customerList && customerList.length > 0)
				) {
					// Lists to build
					var ordersDisplay = [] as OrdersDisplay[]

					//get External Order ID of previousOrder ID
					const getPartnerOrderID = (prevID: number) => {
						//filter based of previous order id
						var previousOrder = orderList.find((o) => o.OrderID === prevID)
						var _partnerOrderID = ''
						if (previousOrder?.PartnerOrderID) {
							_partnerOrderID = previousOrder.PartnerOrderID
						}
						return _partnerOrderID
					}

					// Loop through order list returned
					for (let o = 0; o < orderList.length; o++) {
						// *** Use the generic function to extract the specific properties *** //
						// Customer array: Get customer name and partner reference
						var customerName: string | undefined = extractPropertyByID(
							customerList,
							orderList[o].CustomerID + '',
							'CustomerID',
							'CustomerName'
						)
						var customerRef: string | undefined = extractPropertyByID(
							customerList,
							orderList[o].CustomerID + '',
							'CustomerID',
							'CustomerPartnerReference'
						)

						// Order type
						var orderType = ''
						if (orderList[o].OrderTypeID) {
							orderType = OrderTypes[Number(orderList[o].OrderTypeID)]
						}

						// Order Currency ID
						var orderCurrency = ''
						if (orderList[o].CurrencyID) {
							orderCurrency = Currencies[Number(orderList[o].CurrencyID)]
						}

						// For the partner admin, build the orderSKU display by looping through SKUs linked to orderID
						var orderSKUDisplay = [] as OrderSKUPriceDisplay[]
						if (orderSKUList && orderSKUList.length > 0) {
							// Filter list based on orderID
							var currentOrderSKUList = orderSKUList.filter(
								(sku) => Number(sku.OrderID) === Number(orderList[o].OrderID)
							)

							if (currentOrderSKUList && currentOrderSKUList.length > 0) {
								// Loop through list and build the display array
								for (let sku = 0; sku < currentOrderSKUList.length; sku++) {
									// Order SKU Term array: Get the term year and month based on SKU
									var orderSKUTermYears: number | undefined =
										extractPropertyByID(
											orderSKUTermList,
											Number(currentOrderSKUList[sku].OrderSKUID),
											'OrderSKUID',
											'Years'
										)
									var orderSKUTermMonths: number | undefined =
										extractPropertyByID(
											orderSKUTermList,
											Number(currentOrderSKUList[sku].OrderSKUID),
											'OrderSKUID',
											'Months'
										)
									// Use years and months to get string
									var orderSKUTerm = 'No Term'
									if (orderSKUTermYears || orderSKUTermMonths) {
										orderSKUTerm = getOrderTerm(
											orderSKUTermYears,
											orderSKUTermMonths
										)
									}

									// Build array
									orderSKUDisplay.push({
										OrderSKUID: Number(currentOrderSKUList[sku].OrderSKUID),
										SKUTypeID: currentOrderSKUList[sku].SKUTypeID,
										Quantity: Number(currentOrderSKUList[sku].Quantity),
										Price: Number(currentOrderSKUList[sku].Price),
										NRC: Number(currentOrderSKUList[sku].NRC),
										OrderSKUStatusID:
											OrderSKUStatuses[
												Number(currentOrderSKUList[sku].OrderSKUStatusID)
											],
										ActivationDate: currentOrderSKUList[sku].ActivationDate
											? currentOrderSKUList[sku].ActivationDate + ''
											: '',
										CurrentProduct:
											currentOrderSKUList[sku]?.OrderSKUProductMapList?.filter(
												(item: any) => item.ProductActionStatusID === 'IN-PROG'
											)
												.map((item: any) => item.Product?.ProductName)
												.join(', ') || '',
										OrderSKUTerm: orderSKUTerm,
										OrderCurrency: orderCurrency,
									})
								}
							}
						}

						// Build order display list
						ordersDisplay.push({
							OrderID: orderList[o].OrderID,
							PartnerOrderReference: orderList[o].PartnerOrderID,
							CustomerID: orderList[o].CustomerID,
							CustomerName: customerName,
							ExternalOrderID: orderList[o].ExternalOrderID,
							OrderStatus: getOrderStatus(orderList[o].OrderStatusID + ''),
							CustomerPartnerReference: customerRef,
							OrderType: orderType,
							PreviousOrderID: getPartnerOrderID(
								Number(orderList[o].PreviousOrderID)
							),
							SipcomDirect: orderList[o].SipcomDirect,
							OrderSKUPriceDisplay: orderSKUDisplay,
						})
					}

					// Set the array
					setOrdersDisplay(ordersDisplay)
					setOrderList(orderList)
					setCustomerList(customerList)

					if (skuPartnerMapList && skuPartnerMapList.length > 0) {
						var modifiedSkuPartnerMapList: any[] = skuPartnerMapList.map(
							(x) => x.SKUTypeID
						)
						setSkuPartnerMapList(modifiedSkuPartnerMapList)
						setOrderSKUList(orderSKUList)

						var standardNoRangeNoTermSKUList = skuPartnerMapList.filter(
							(x) =>
								x.PricingTypeID === PricingTypes['Standard No Range No Term'] &&
								x.PriceBookList?.every(
									(priceBook) => priceBook.SipcomMRC === 0.0
								)
						)

						setStandardNoRangeNoTermSKUs(standardNoRangeNoTermSKUList)
					}
				}
			}

			// Once all is done
			setAPICallsDone(true)
		} catch {
			// Set error display
			setError('An error occurred', 'An error occurred when retrieving orders')
		}
	}

	// Function to extract unique SKUs and SKUs Terms
	function extractSKUsAndTerms(orders: Order[]) {
		const skuMap = new Map<number, OrderSKU>()
		const skuTermMap = new Map<number, OrderSKUTerm>()

		orders.forEach((order) => {
			if (order.OrderSKUList) {
				order.OrderSKUList.forEach((sku) => {
					if (sku.OrderSKUID !== undefined) {
						skuMap.set(sku.OrderSKUID, sku)
					}

					if (sku.OrderSKUTermList) {
						sku.OrderSKUTermList.forEach((term) => {
							if (term.OrderSKUTermID !== undefined) {
								skuTermMap.set(term.OrderSKUTermID, term)
							}
						})
					}
				})
			}
		})

		return {
			orderSKUList: Array.from(skuMap.values()),
			orderSKUTermList: Array.from(skuTermMap.values()),
		}
	}

	// *** Handle Functions *** //
	// Return the order term based on year and month
	const getOrderTerm = (
		years: number | undefined,
		month: number | undefined
	): string => {
		// Return variables
		var results = ''
		var YearsString = ''
		var MonthString = ''

		// Year
		if (years !== undefined) {
			if (years === 1) {
				YearsString = years + ' Year'
			} else if (years > 1) {
				YearsString = years + ' Years'
			} else if (years < 1) {
				YearsString = ''
			}
		}

		// Month
		if (month !== undefined) {
			if (month === 1) {
				MonthString = month + ' Month'
			} else if (month > 1) {
				MonthString = month + ' Months'
			} else if (month < 1) {
				MonthString = ''
			}
		}

		results = YearsString + ' ' + MonthString
		return results
	}

	// Return order status based on ID
	function getOrderStatus(orderStatusID: string): string {
		// Check the order status ID and return string
		switch (orderStatusID) {
			case OrderStatuses.AwaitingPartnerApproval:
				return 'Awaiting Partner Confirmation'
			case OrderStatuses.PartnerApproved:
				return 'Partner Confirmed'
			case OrderStatuses.AwaitingSalesApproval:
				return 'Awaiting Sales Approval'
			case OrderStatuses.SalesApproved:
				return 'Sales Approved'
			case OrderStatuses.AwaitingFinanceApproval:
				return 'Awaiting Finance Approval'
			case OrderStatuses.FinanceApproved:
				return 'Finance Approved'
			case OrderStatuses.SystemApproved:
				return 'System Approved'
			case OrderStatuses.InProgress:
				return 'In Progress'
			case OrderStatuses.Complete:
				return 'Complete'
			case OrderStatuses.Rejected:
				return 'Rejected'
			case OrderStatuses.Active:
				return 'Active'
			default:
				return ''
		}
	}

	return apiCallsDone ? (
		// Display datagrid
		<OrdersOverviewDisplay
			ordersDisplay={ordersDisplay}
			orderList={orderList}
			customerList={customerList}
			skuPartnerMapList={skuPartnerMapList}
			orderSKUList={orderSKUList}
			standardNoRangeNoTermSKUs={standardNoRangeNoTermSKUs}
			numberOfRows={numberOfRows}
			getOrders={getOrders}
			isCustomerOverview={
				customerSpecificID && customerSpecificID.length > 0 ? true : false
			}
		/>
	) : errorMessage.length > 0 ? (
		<ErrorDisplay errorTitle={errorTitle} errorDescription={errorMessage} />
	) : (
		// Show loading
		<LoadingBox title='Getting Orders' />
	)
}

export default OrdersOverview
