import { Box, Divider, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import { ContactDetailTypes, ReturnTypes } from '../../../../utils/enums/enums'
import {
	ContactDetail,
	ContactDetailMap,
} from '../../../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	emailValidation,
	showErrorToast,
} from '../../../../utils/helperFunctions/helperFunctions'
import LoadingBox from '../../../shared/displays/LoadingBox/LoadingBox'
import { StyledLoadingButton } from '../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { StyledTextField } from '../../../../styles/styledComponents/inputs/StyledTextField'

function CommunicationManagement() {
	//Hooks
	const { fetchData, modifyData } = UseCrud()

	//flags
	const [gettingData, setGettingData] = useState(true)
	const [dataSaving, setDataSaving] = useState(false)

	//const
	const [admin, setAdmin] = useState('')
	const [sales, setSales] = useState('')
	const [engineering, setEngineering] = useState('')
	const [billing, setBilling] = useState('')
	const [finance, setFinance] = useState('')

	const [hasAdmin, setHasAdmin] = useState(false)
	const [hasBilling, setHasBilling] = useState(false)
	const [hasSales, setHasSales] = useState(false)
	const [hasEngineering, setHasEngineering] = useState(false)
	const [hasFinance, setHasFinance] = useState(false)

	const hasAdminExecuted = useRef(false)
	const hasSalesExecuted = useRef(false)
	const hasEngineeringExecuted = useRef(false)
	const hasBillingExecuted = useRef(false)
	const hasFinanceExecuted = useRef(false)

	const [contactDetails, setContactDetails] = useState([] as ContactDetail[])
	const [contactDetailMap, setContactDetailMap] = useState(
		[] as ContactDetailMap[]
	)

	//Redux
	var partner = useSelector(
		(state: RootState) => state.RootReducer.customerPartnerReducer.value
	)
	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	useEffect(() => {
		getEmails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const findAndSetContact = (
		type: ContactDetailTypes,
		setHasState: React.Dispatch<React.SetStateAction<boolean>>,
		setEmailState: React.Dispatch<React.SetStateAction<string>>,
		contactDetailMap: ContactDetailMap[]
	) => {
		const contact = contactDetailMap.find(
			(detail) => detail?.ContactDetail?.ContactDetailTypeID === type
		)
		if (contact) {
			setHasState(true)
			setEmailState(contact.ContactDetail?.ContactPersonEmail + '')
		}

		return contact?.ContactDetail ?? {}
	}

	const handleContactDetailMap = async (
		contactDetailTypeIDs: string,
		emails: string
	) => {
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CommunicationManagement: handleContactDetailMap()',
			QueryURL: `GetV2?Params=ContactDetail.where(ContactDetail.ContactPersonEmail^'${emails}' 
			& ContactDetail.ContactDetailTypeID ^ '${contactDetailTypeIDs}' & ContactDetail.isRoleEmail = 'true')`,
			ErrorMessage: 'An error occurred when getting information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _contactDetail = dataResponse.Obj.ContactDetailList as ContactDetail[]

			var contactDetailMapDetailListObj = _contactDetail.map((e) => {
				return {
					ContactDetailId: e.ContactDetailID,
					CustomerPartnerID: partner.CustomerPartnerID,
					CarrierID: 1,
					CustomerID: '000000',
				}
			})

			var _contactDetailMapListObj = {
				ContactDetailMapList: contactDetailMapDetailListObj,
			}

			await modifyData({
				FileAndFunctionName: 'CommunicationManagement: handleUpdate()',
				QueryURL: `AddV2?Params=ContactDetailMap:list`,
				ErrorMessage: 'An error occurred when Adding Email(s)',
				QueryObj: _contactDetailMapListObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully Added Email(s)',
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
		}
	}

	const clearEverything = () => {
		hasAdminExecuted.current = false
		hasSalesExecuted.current = false
		hasBillingExecuted.current = false
		hasEngineeringExecuted.current = false
		hasFinanceExecuted.current = false

		setHasAdmin(false)
		setHasBilling(false)
		setHasEngineering(false)
		setHasEngineering(false)
		setHasFinance(false)

		setContactDetailMap([])
		setContactDetails([])
	}

	const getEmails = async () => {
		// Make call
		clearEverything()
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CommunicationManagement: getEmails()',
			QueryURL: `GetV2?Params=ContactDetailmap.ContactDetail.where(ContactDetailMap.CustomerPartnerID = '${partner.CustomerPartnerID}'
			 & ContactDetail.isRoleEmail = 'true')`,
			ErrorMessage: 'An error occurred when getting information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _contactDetailMap = dataResponse.Obj
				.ContactDetailMapList as ContactDetailMap[]
			var _contactDetails = [] as ContactDetail[]

			setContactDetailMap(_contactDetailMap)
			_contactDetails.push(
				findAndSetContact(
					ContactDetailTypes.Admin,
					setHasAdmin,
					setAdmin,
					_contactDetailMap
				)
			)
			_contactDetails.push(
				findAndSetContact(
					ContactDetailTypes.Billing,
					setHasBilling,
					setBilling,
					_contactDetailMap
				)
			)
			_contactDetails.push(
				findAndSetContact(
					ContactDetailTypes.Engineering,
					setHasEngineering,
					setEngineering,
					_contactDetailMap
				)
			)
			_contactDetails.push(
				findAndSetContact(
					ContactDetailTypes.Sales,
					setHasSales,
					setSales,
					_contactDetailMap
				)
			)
			_contactDetails.push(
				findAndSetContact(
					ContactDetailTypes.Finance,
					setHasFinance,
					setFinance,
					_contactDetailMap
				)
			)
			setContactDetails(_contactDetails)
		}
		setGettingData(false)
	}

	const handleDetectChange = (
		contactDetail: ContactDetail
	): { type?: number; email: string } => {
		if (Object.keys(contactDetail).length > 0) {
			switch (contactDetail.ContactDetailTypeID) {
				case ContactDetailTypes.Admin:
					if (contactDetail.ContactPersonEmail !== admin)
						return { email: admin }
					break
				case ContactDetailTypes.Billing:
					if (contactDetail.ContactPersonEmail !== billing)
						return { email: billing }
					break
				case ContactDetailTypes.Engineering:
					if (contactDetail.ContactPersonEmail !== engineering)
						return { email: engineering }
					break

				case ContactDetailTypes.Sales:
					if (contactDetail.ContactPersonEmail !== sales)
						return { email: sales }
					break

				case ContactDetailTypes.Finance:
					if (contactDetail.ContactPersonEmail !== finance)
						return { email: finance }
					break
			}
		}
		return { email: 'pass' }
	}

	const handleDeletedEmails = (): { type?: number; email: string } => {
		if (!hasAdmin && admin && !hasAdminExecuted.current) {
			setHasAdmin(true)
			hasAdminExecuted.current = true
			return { type: ContactDetailTypes.Admin, email: admin }
		}
		if (!hasBilling && billing && !hasBillingExecuted.current) {
			setHasBilling(true)
			hasBillingExecuted.current = true
			return { type: ContactDetailTypes.Billing, email: billing }
		}
		if (!hasSales && sales && !hasSalesExecuted.current) {
			setHasSales(true)
			hasSalesExecuted.current = true
			return { type: ContactDetailTypes.Sales, email: sales }
		}
		if (!hasEngineering && engineering && !hasEngineeringExecuted.current) {
			setHasEngineering(true)
			hasEngineeringExecuted.current = true
			return { type: ContactDetailTypes.Engineering, email: engineering }
		}
		if (!hasFinance && finance && !hasFinanceExecuted.current) {
			setHasFinance(true)
			hasFinanceExecuted.current = true
			return { type: ContactDetailTypes.Finance, email: finance }
		}
		return { email: 'pass' }
	}

	const handleUpdate = async () => {
		if (
			(admin && !emailValidation(admin.trim())) ||
			(billing && !emailValidation(billing.trim())) ||
			(engineering && !emailValidation(engineering.trim())) ||
			(sales && !emailValidation(sales.trim())) ||
			(finance && !emailValidation(finance.trim()))
		) {
			showErrorToast('Please ensure that all emails are in the correct format.')
			return
		}

		var updateObjList = [] as ContactDetail[]
		var deleteObjList = [] as ContactDetail[]
		var addObjList = [] as ContactDetail[]

		contactDetails.forEach((contactDetail) => {
			var emailToUpdate = handleDetectChange(contactDetail)

			if (emailToUpdate.email !== 'pass') {
				if (emailToUpdate.email !== '') {
					if (!emailToUpdate.type) {
						updateObjList.push({
							ContactDetailID: contactDetail.ContactDetailID,
							ContactPersonEmail: emailToUpdate.email,
						})
					}
				} else {
					var contactDetails = contactDetailMap.filter(
						(e) =>
							e.ContactDetail?.ContactDetailTypeID ===
							contactDetail.ContactDetailTypeID
					)
					var contactDetailIDs = contactDetails.map((e) => {
						return { ContactDetailID: e.ContactDetailID }
					})
					deleteObjList.push(...contactDetailIDs)
				}
			}
		})

		const keys = Object.keys(ContactDetailTypes)
		keys.forEach((key) => {
			if (parseInt(key)) {
				var emailToAdd = handleDeletedEmails()
				if (emailToAdd.email !== 'pass') {
					addObjList.push({
						ContactPersonEmail: emailToAdd.email,
						ContactDetailTypeID: emailToAdd.type,
						IsRoleEmail: true,
					})
				}
			}
		})

		if (
			updateObjList.length === 0 &&
			deleteObjList.length === 0 &&
			addObjList.length === 0
		) {
			showErrorToast('No Changes Detected')
			return
		}

		setDataSaving(true)

		if (updateObjList.length > 0) {
			var _updateObjList = {
				ContactDetailList: updateObjList,
			}
			await modifyData({
				FileAndFunctionName: 'CommunicationManagement: handleUpdate()',
				QueryURL: `UpdateV2?Params=ContactDetail:list`,
				ErrorMessage: 'An error occurred when updating Email(s)',
				QueryObj: _updateObjList,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully Updated Email(s)',
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
		}

		if (deleteObjList.length > 0) {
			var _deleteObjList = {
				contactDetailMapList: deleteObjList,
				ContactDetailList: deleteObjList,
			}

			await modifyData({
				FileAndFunctionName: 'CommunicationManagement: handleUpdate()',
				QueryURL: `DeleteV2?Params=ContactDetailMap:list,ContactDetail:list`,
				ErrorMessage: 'An error occurred when Removing Email(s)',
				QueryObj: _deleteObjList,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully Removed Email(s)',
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
		}

		if (addObjList.length > 0) {
			var _addObjList = {
				ContactDetailList: addObjList,
			}

			await modifyData({
				FileAndFunctionName: 'CommunicationManagement: handleUpdate()',
				QueryURL: `AddV2?Params=ContactDetail:list`,
				ErrorMessage: 'An error occurred when Adding Email(s)',
				QueryObj: _addObjList,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully Added Email(s)',
				ShowErrorMessage: true,
				ShowSuccessMessage: false,
			})
			var contactDetailTypeIDs = addObjList
				.map((obj) => obj.ContactDetailTypeID)
				.join(',')
			var contactPersonEmails = addObjList
				.map((obj) => obj.ContactPersonEmail)
				.join(',')

			await handleContactDetailMap(contactDetailTypeIDs, contactPersonEmails)
		}

		await getEmails()
		setDataSaving(false)
	}

	return (
		<Box>
			<Box className='description'>
				<Typography component='p'>
					You can set all the e-mail endpoints here to which you would like to
					receive communication.
				</Typography>
			</Box>
			<Divider />
			{!gettingData ? (
				<>
					<Box className='admin-title-container'>
						<Box className='admin-title'>
							<Typography
								sx={{
									marginBottom: '1rem',
								}}
								component='h4'>
								Role communication settings:
							</Typography>
							<Typography sx={{ color: '#808080' }} component='p'>
								Here you can set the e-mail endpoints for specific role required
								communication.
							</Typography>
						</Box>

						<Box>
							<StyledLoadingButton
								loading={dataSaving}
								onClick={handleUpdate}
								variant='outlined'>
								Save & Update
							</StyledLoadingButton>
						</Box>
					</Box>
					<Box display='flex' alignItems='center'>
						<Typography
							sx={{ color: '#000', marginRight: '5rem' }}
							component='h4'>
							Role Communication:
						</Typography>
						<Typography
							sx={{ color: '#000', marginRight: '10rem' }}
							component='h4'>
							Role Type:
						</Typography>
						<Typography sx={{ color: '#000' }} component='h4'>
							Email Group:
						</Typography>
					</Box>
					<Box display='flex' alignContent='center'>
						<Box sx={{ marginLeft: '16.1rem' }}>
							<Box display='flex' alignItems='center'>
								<Typography sx={{ width: '400px', marginTop: '0.5rem' }}>
									Admin:
								</Typography>{' '}
								<StyledTextField
									onChange={(e) => setAdmin(e.target.value.trim())}
									value={admin}
									sx={{ margin: '1.5rem 0' }}
									fullWidth></StyledTextField>
							</Box>
							<Box display='flex' alignItems='center'>
								<Typography sx={{ width: '400px', marginTop: '0.5rem' }}>
									Sales:
								</Typography>{' '}
								<StyledTextField
									onChange={(e) => setSales(e.target.value.trim())}
									value={sales}
									sx={{ margin: '1.5rem 0' }}
									fullWidth
								/>
							</Box>
							<Box display='flex' alignItems='center'>
								<Typography sx={{ width: '400px', marginTop: '0.5rem' }}>
									Engineering:
								</Typography>{' '}
								<StyledTextField
									onChange={(e) => setEngineering(e.target.value.trim())}
									value={engineering}
									sx={{ margin: '1.5rem 0' }}
									fullWidth
								/>
							</Box>
							<Box display='flex' alignItems='center'>
								<Typography sx={{ width: '400px', marginTop: '0.5rem' }}>
									Billing:
								</Typography>{' '}
								<StyledTextField
									onChange={(e) => setBilling(e.target.value.trim())}
									value={billing}
									sx={{ margin: '1.5rem 0' }}
									fullWidth
								/>
							</Box>
							<Box display='flex' alignItems='center'>
								<Typography sx={{ width: '400px', marginTop: '0.5rem' }}>
									Finance:
								</Typography>{' '}
								<StyledTextField
									onChange={(e) => setFinance(e.target.value.trim())}
									value={finance}
									sx={{ margin: '1.5rem 0' }}
									fullWidth
								/>
							</Box>
						</Box>
					</Box>
				</>
			) : (
				<LoadingBox title='Getting Emails' />
			)}
		</Box>
	)
}

export default CommunicationManagement
