import { Avatar, Box, Chip, MenuItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Customer } from '../../../../utils/interfaces/DBModels'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import { CustomerStatuses, ReturnTypes } from '../../../../utils/enums/enums'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import LoadingBox from '../../../../components/shared/displays/LoadingBox/LoadingBox'
import SimpleCard from '../../../../components/shared/displays/AnimatedBox/SimpleCardContent/SimpleCardContent'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { stringAvatar } from '../../../../utils/helperFunctions/helperFunctions'
import './CustomerOverview.scss'
import { StyledSelect } from '../../../../styles/styledComponents/inputs/StyledSelect'
import AnimatedBox from '../../../../components/shared/displays/AnimatedBox/AnimatedBox'
import CustomerTabs from '../../../../components/shared/pageShared/CustomersOverview/CustomerTabs/CustomerTabs'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

// Display: Show if customer is not found in our system
const NoCustomerFound = () => {
	// General
	const navigate = useNavigate()

	return (
		<AnimatedBox
			children={
				<SimpleCard
					title='No Customer Found'
					description='Please note there was no information related to the customer found in our system. Please return to customer management'
					icon={<CloseOutlinedIcon />}
					showAction={true}
					action={() => navigate(-1)}
					actionTooltip='Return to Customer Management'
				/>
			}
			success={false}
		/>
	)
}

function CustomerOverview() {
	// Global variables
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Hooks
	const { fetchData } = UseCrud()

	// *** Location constants - Used to change the search params and more *** //
	// General
	const location = useLocation()
	const navigate = useNavigate()
	const searchParams = new URLSearchParams(location.search)

	// *** Display constants *** //
	// Get customerID from search params in URL
	const customerID = searchParams.get('customerID') || ''
	// General
	const [customerList, setCustomerList] = useState([] as Customer[])
	const [customer, setCustomer] = useState({} as Customer)

	// Flags
	const [customerCallMade, setCustomerCallMade] = useState(false)
	const [initialCallDone, setInitialCallDone] = useState(false)
	const [customerFound, setCustomerFound] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)

	useEffect(() => {
		// Make call if not already done
		if (!customerCallMade) {
			setCustomerCallMade(true)
			getCustomerInformation()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerCallMade, customerID, customer, apiCallsDone, initialCallDone])

	// *** API Calls *** //
	// GET: Return customer information based on customerID
	const getCustomerInformation = async () => {
		// Make call only if customerID is greater than 0
		if (customerID.length > 0) {
			// First Call: Get the list
			var customerInfoFound = await getCustomers()
			setInitialCallDone(true)
			setCustomerFound(customerInfoFound)

			// Second Call (If customer info is found): Get service management information
		}

		// Stop loading
		setAPICallsDone(true)
	}

	// GET: Return customer list
	const getCustomers = async () => {
		// Return boolean
		var customerInfoFound = false

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CustomerOverview.tsx: getCustomers()',
			QueryURL: `GetV2?Params=Customer.Where(Customer.CustomerPartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting order information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerList = dataResponse.Obj.CustomerList as Customer[]

			if (customerList && customerList.length > 0) {
				// Set list
				setCustomerList(customerList)

				// Use list and get current customer
				var currentCustomer = customerList.find(
					(c) => c.CustomerID + '' === customerID
				)
				if (currentCustomer) {
					setCustomer(currentCustomer)
					customerInfoFound = true
				}
			}
		}

		return customerInfoFound
	}

	// *** Handle Functions *** //
	// Handle change of customer dropdown
	const handleCustomerChange = (customerID: string) => {
		// Update the search param to change customerID
		searchParams.set('customerID', customerID)
		resetFlags()
		navigate({ search: searchParams.toString() })
	}

	// Handle reset of flags
	const resetFlags = () => {
		setInitialCallDone(false)
		setCustomerFound(false)
		setCustomerCallMade(false)
	}

	return (
		<>
			<Box id='customer-overview' className='customer-overview-container'>
				{/* First layout - Customer Overview and SM Details */}
				<Box className='customer-overview-content'>
					{/* Box 1 - Customer Overview Information */}
					{initialCallDone ? (
						<>
							{customerFound ? (
								<AnimatedBox
									success
									children={
										<Box className='customer-information-container'>
											{/* Avatar */}
											<Avatar {...stringAvatar(customer.CustomerName + '')} />
											{/* Customer Information */}
											<Box className='customer-information'>
												{/* Customer ID and Name dropdown */}
												<Box className='info'>
													<Typography component='label'>
														Customer ID and Name
													</Typography>
													<StyledSelect
														fullWidth
														required
														variant='outlined'
														value={customerID}
														onChange={(e) =>
															handleCustomerChange(e.target.value + '')
														}>
														{/* Map customer */}
														{customerList.map((item, index) => (
															<MenuItem key={index} value={item.CustomerID}>
																{`${item.CustomerID} - ${item.CustomerName}`}
															</MenuItem>
														))}
													</StyledSelect>
												</Box>
												{/* Reference */}
												<Box className='info'>
													<Typography component='label'>
														Customer Reference
													</Typography>
													<Typography component='p'>
														{customer.CustomerPartnerReference
															? customer.CustomerPartnerReference
															: '<empty>'}
													</Typography>
												</Box>
												{/* Status */}
												<Box className='info'>
													<Typography component='label'>
														Customer Status
													</Typography>
													<Chip
														label={
															CustomerStatuses[
																Number(customer.CustomerStatusID)
															]
														}
														variant='filled'
														color={
															Number(customer.CustomerStatusID) ===
															CustomerStatuses.Active
																? 'success'
																: 'error'
														}
													/>
												</Box>
											</Box>
										</Box>
									}
								/>
							) : (
								<NoCustomerFound />
							)}
						</>
					) : (
						<LoadingBox title='Getting Customer Information' />
					)}
				</Box>
				{/* Second layout - Tabbed orders, DDIs and trunks */}
				<CustomerTabs customerID={customerID} />
			</Box>
		</>
	)
}

export default CustomerOverview
