import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { StyledAnimatedBox } from '../../../../styles/styledComponents/displays/StyledAnimatedBox'
import SimpleCard from '../AnimatedBox/SimpleCardContent/SimpleCardContent'
import HandymanIcon from '@mui/icons-material/Handyman'
import './SMNumberDisplayBox.scss'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const SMNumberDisplayBox = ({
	title,
	description,
	smTeamsDRUsersCount,
	smCallQueuesCount,
	smAutoAttendantsCount,
	smTotalEndpoints,
	loading,
	requiresSMInstall,
	onSMInstall,
}: {
	title: string
	description: string
	smTeamsDRUsersCount: number
	smCallQueuesCount: number
	smAutoAttendantsCount: number
	smTotalEndpoints: number
	loading: boolean
	requiresSMInstall: boolean
	onSMInstall: any
}) => {
	const theme = useTheme()

	return (
		<StyledAnimatedBox sx={{ padding: '0px', margin: '0px' }}>
			<Box className='sm-container'>
				{/* Title and Description */}
				<SimpleCard
					icon={<HandymanIcon style={{ color: theme.palette.primary.main }} />}
					title={title}
					description={description}
					showAction={false}
				/>

				{!loading ? (
					!requiresSMInstall ? (
						<>
							<Box className='centerBox'>
								<Typography component='h6'>Direct Routing:</Typography>
							</Box>

							<Box className='sm-content'>
								<Box className='sm-content-flex'>
									{/* smTeamsDRUsersCount */}
									<Box className='sm-content-col'>
										<Typography component='h6'>Platform Users</Typography>
										<Typography component='p'>
											{smTeamsDRUsersCount + ''}
										</Typography>
									</Box>

									{/* smCallQueuesCount */}
									<Box className='sm-content-col'>
										<Typography component='h6'>Call Queues</Typography>
										<Typography component='p'>
											{smCallQueuesCount + ''}
										</Typography>
									</Box>

									{/* smAutoAttendantsCount */}
									<Box className='sm-content-col'>
										<Typography component='h6'>Auto Attendants</Typography>
										<Typography component='p'>
											{smAutoAttendantsCount + ''}
										</Typography>
									</Box>

									{/* smTotalEndpoints */}
									<Box className='sm-content-col'>
										<Typography component='h6'>Total Endpoints</Typography>
										<Typography component='p'>
											{smTotalEndpoints + ''}
										</Typography>
									</Box>
								</Box>
							</Box>
						</>
					) : (
						<Box sx={{ alignItems: 'center' }}>
							<Box className='centerBox'>
								<Typography component='h6'>Install Service Manager</Typography>
								<Box sx={{ marginTop: '-8px' }}>
									<Tooltip
										title='Please ensure that pop-ups are enabled in your browser before
									proceeding'>
										<span>
											<IconButton>
												<WarningAmberIcon sx={{ color: 'orange' }} />
											</IconButton>
										</span>
									</Tooltip>
								</Box>
							</Box>

							<Box className='centerBox' sx={{ marginTop: '15px' }}>
								<Button
									sx={{ maxWidth: '500px' }}
									onClick={() => {
										onSMInstall()
									}}
									variant='contained'>
									Install Service Manager
								</Button>
							</Box>
						</Box>
					)
				) : (
					<Box className='centerBox'>
						<CircularProgress size={52} color='inherit' />
					</Box>
				)}
			</Box>
		</StyledAnimatedBox>
	)
}
