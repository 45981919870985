import { useEffect, useState } from 'react'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import {
	AddressMap,
	Customer,
	DDI,
	DDIStatus,
} from '../../../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ReturnTypes, Roles } from '../../../../utils/enums/enums'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import TNOverviewDisplay from './TNOverviewDisplay/TNOverviewDisplay'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import {
	DDIDisplay,
	DDIRangeDisplay,
} from '../../../../utils/interfaces/ComponentModels'
import EmptyDisplay from '../../displays/EmptyDisplay/EmptyDisplay'

const TNOverview = ({
	numberOfRows,
	customerSpecificID,
}: {
	numberOfRows: number
	customerSpecificID?: string
}) => {
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [hasRows, setHasRows] = useState(false)

	// Global variables
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Arrays
	const [tnList, setTNList] = useState([] as DDI[])
	const [addressMapList, setAddressMapList] = useState([] as AddressMap[])
	const [ddiStatusList, setDDIStatusList] = useState([] as DDIStatus[])
	const [tnDisplay, setTNDisplay] = useState([] as DDIDisplay[])
	const [tnRangeDisplay, setTNRangeDisplay] = useState([] as DDIRangeDisplay[])

	// Hooks
	const { fetchData } = UseCrud()

	useEffect(() => {
		getTNs()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerSpecificID])

	// GET: Retrieve TNs
	const getTNs = async () => {
		setAPICallsDone(false)
		setHasRows(false)
		var requestURL: string = ''

		// Set customer ID to use based on role or passed in parameter
		var customerIDToUse = ''
		if (roleID === Roles.CustomerAdmin) {
			customerIDToUse = loggedInUser.customerID
		}
		if (customerSpecificID && customerSpecificID.length > 0) {
			customerIDToUse = customerSpecificID
		}

		// Check on customerID - If there is a value then filter based on it else get all TNs
		if (customerIDToUse.length > 0) {
			requestURL = `GetV2?Params=DDI.DDIRange.Where(DDIRange.CustomerID = '${customerIDToUse}'), Customer.Where(Customer.CustomerID = '${customerIDToUse}'), DDIStatus.All(), DDIType.All(), AddressMap.Address.State.Country.Where(AddressMap.CustomerID = '${customerIDToUse}')`
		} else {
			requestURL = `GetV2?Params=DDI.DDIRange.Customer.Where(Customer.CustomerPartnerID= '${partnerID}'), Customer.Where(Customer.CustomerPartnerID= '${partnerID}'), DDIStatus.All(), DDIType.All(), AddressMap.Customer.Address.State.Country.Where(Customer.CustomerPartnerID = '${partnerID}')`
		}

		// Make call based on request URL
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TNOverview.tsx: getTNs()',
			QueryURL: requestURL,
			ErrorMessage: 'An error occurred when getting TN information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// *** Get the all relevant lists and build the display array *** //
			var _ddiStatusList = dataResponse.Obj.DDIStatusList as DDIStatus[]
			if (_ddiStatusList) {
				setDDIStatusList(_ddiStatusList)
			}
			var _addressMapList = dataResponse.Obj.AddressMapList as AddressMap[]
			if (_addressMapList) {
				setAddressMapList(_addressMapList)
			}
			var _ddiList = dataResponse.Obj.DDIList as DDI[]
			if (_ddiList && _ddiList.length > 0) {
				setHasRows(true)
				setTNList(_ddiList)
			}
			var _customerList = dataResponse.Obj.CustomerList as Customer[]

			var _ddiDisplayList = [] as DDIDisplay[]
			var _ddiRangeDisplayList = [] as DDIRangeDisplay[]
			for (let index = 0; index < _ddiList?.length; index++) {
				const ddi = _ddiList[index]
				const ddiRange = ddi.DDIRange
				var ddiStaus = _ddiStatusList.find(
					(x) => Number(x.DDIStatusID) === Number(ddi.DDIStatusID)
				)
				var ddiAddressMap = {} as AddressMap
				if (ddi.AddressID) {
					ddiAddressMap = _addressMapList.find(
						(x) => Number(x.AddressID) === Number(ddi.AddressID)
					) as AddressMap
				}

				var customerName = ''
				if (_customerList && _customerList.length > 0) {
					customerName =
						_customerList.find((x) => x.CustomerID === ddi.DDIRange?.CustomerID)
							?.CustomerName + ''
				}

				var _ddiDisplay: DDIDisplay = {
					ID: Number(ddi.ID),
					DDI: ddi.DDI + '',
					DDIRangeID: Number(ddi.DDIRangeID),
					DDIStatus: ddiStaus?.DDIStatusName + '',
					AddressMapID: Number(ddiAddressMap?.AddressMapID),
					AddressFriendlyName: ddiAddressMap?.AddressName ?? '',
					PortDate: ddi.PortDate ? ddi.PortDate + '' : '',
					CustomerName: customerName,
					CustomerID: ddi.DDIRange?.CustomerID + '',
					IngressServiceID: ddi.ServiceInID ?? '',
					EgressServiceID: ddi.ServiceOutID ?? '',
				}
				_ddiDisplayList.push(_ddiDisplay)

				var _ddiRangeDisplay: DDIRangeDisplay = {
					DDIRangeID: Number(ddiRange?.DDIRangeID),
					DDIRangeStart: ddiRange?.DDIRangeStart + '',
					DDIRangeEnd: ddiRange?.DDIRangeEnd + '',
					IsRangeActive: ddiRange?.IsActive,
					CustomerName: customerName,
					CustomerID: ddiRange?.CustomerID + '',
					PortDate: ddi.PortDate ? ddi.PortDate + '' : '',
				}
				if (
					!_ddiRangeDisplayList.find(
						(x) => Number(x.DDIRangeID) === Number(ddiRange?.DDIRangeID)
					)
				) {
					_ddiRangeDisplayList.push(_ddiRangeDisplay)
				}
			}
			setTNDisplay(_ddiDisplayList)
			setTNRangeDisplay(_ddiRangeDisplayList)
		}
		setAPICallsDone(true)
	}

	return apiCallsDone ? (
		hasRows ? (
			// Display datagrid
			<TNOverviewDisplay
				ddiDisplay={tnDisplay}
				ddiRangeDisplay={tnRangeDisplay}
				ddiList={tnList}
				ddiStatusList={ddiStatusList}
				addressMapList={addressMapList}
				numberOfRows={numberOfRows}
				getTNs={getTNs}
			/>
		) : (
			<EmptyDisplay
				title='No TNs found'
				description='There were no TNs found'
			/>
		)
	) : (
		// Show loading
		<LoadingBox title='Getting TNs' />
	)
}

export default TNOverview
