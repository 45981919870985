import { useCallback, useEffect, useState } from 'react'
import { DDIDisplay } from '../../../../../utils/interfaces/ComponentModels'
import {
	AddressMap,
	DDI,
	DDIStatus,
} from '../../../../../utils/interfaces/DBModels'
import { format } from 'date-fns'
import {
	GridColDef,
	GridFilterModel,
	GridRenderCellParams,
	GridToolbar,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DDIAddressUpdate from './DDIAddressUpdate'
import { IconButton, Modal, Tooltip } from '@mui/material'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import { showErrorToast } from '../../../../../utils/helperFunctions/helperFunctions'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { Roles } from '../../../../../utils/enums/enums'

const TNDisplay = ({
	ddiDisplay,
	ddiList,
	ddiStatusList,
	addressMapList,
	numberOfRows,
	getTNs,
}: {
	ddiDisplay: DDIDisplay[]
	ddiList: DDI[]
	ddiStatusList: DDIStatus[]
	addressMapList: AddressMap[]
	numberOfRows: number
	getTNs: any
}) => {
	// Display constants
	const [tnRows, setTNRows] = useState([] as DDIDisplay[])
	const [allColumnsSet, setAllColumnsSet] = useState(false)
	const [filterAddressMapList, setFilterAddressMapList] = useState(
		[] as AddressMap[]
	)
	const [selectedDDIDisplay, setSelectedDDIDisplay] = useState({} as DDIDisplay)
	const [ddiStatusFilterList, setDDIStatusFilterList] = useState(
		[] as DDIStatus[]
	)
	const [openModal, setOpenModal] = useState(false)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	// Hooks
	const { modifyData } = UseCrud()
	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// *** Datagrid Specific Variables *** //
	// Pagination
	const [paginationModel, setPaginationModel] = useState({
		pageSize: numberOfRows,
		page: 0,
	})

	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	// Handle functions
	const handleUpdateTNAddressClick = async (ddiID: number) => {
		if (ddiID) {
			var _selectedDDIDisplay = ddiDisplay.find((x) => x.ID === ddiID)

			if (_selectedDDIDisplay) {
				setSelectedDDIDisplay(_selectedDDIDisplay)
			}
			// filter address list to addresses by customer and dialing code
			var filteredAddresses = addressMapList.filter((x) => {
				var dialingCode = x.Address?.State?.Country?.CountryDiallingCode
				return (
					dialingCode ===
						_selectedDDIDisplay?.DDI?.substring(0, dialingCode?.length) &&
					_selectedDDIDisplay?.CustomerID === x.CustomerID
				)
			})
			setFilterAddressMapList(filteredAddresses)
			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const handleAdressUpdate = async (addressMapID: number, ddiID?: number) => {
		if (addressMapID && ddiID) {
			var matchingAddressMap = addressMapList.find(
				(x) => x.AddressMapID === Number(addressMapID)
			)
			var _ddiObj: DDI = {
				ID: ddiID,
				AddressID: matchingAddressMap?.AddressID,
			}
			try {
				// Post to DB
				var postSuccess = await modifyData({
					UserName: loggedInUser.email,
					FileAndFunctionName: `TNDisplay.tsx: Update TN Location`,
					QueryURL: 'UpdateV2?Params=DDI',
					QueryObj: {
						DDI: _ddiObj,
					},
					ShowSuccessMessage: true,
					SuccessMessage: `Successfully updated TN location`,
					ShowErrorMessage: false,
					ErrorMessage: `Failed to update TN location`,
					LogErrorToDB: true,
				})

				if (postSuccess) {
					await getTNs()
					handleCloseModal()
				}
			} catch (error) {
				showErrorToast(`An error occurred when trying to update TN location`)
			}
		}
	}

	// Column Definition: Order Table
	const initialTNColumns: GridColDef[] = [
		{
			field: 'DDI',
			headerName: 'TN',
			hideable: false,
			flex: 1,
		},
		{
			field: 'AddressFriendlyName',
			headerName: 'TN Location',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'IngressServiceID',
			headerName: 'Ingress Service',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'EgressServiceID',
			headerName: 'Egress Service',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIStatus',
			headerName: 'TN Status',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PortDate',
			headerName: 'FOC Date',
			width: 225,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
				<>{params.value ? format(params.value, 'MMMM dd, yyyy') : ''}</>
			),
		},
	]
	const [tnColumns, setTNColumns] = useState<GridColDef[]>(initialTNColumns)

	useEffect(() => {
		// Set rows
		if (ddiDisplay.length > 0) {
			setTNRows(ddiDisplay)

			if (roleID === Roles.PartnerAdmin) {
				initialTNColumns.push({
					field: 'CustomerName',
					headerName: 'Customer',
					width: 220,
					hideable: false,
					flex: 1,
				})
			}

			// Add actions column for all roles
			initialTNColumns.push({
				field: 'ID',
				type: 'actions',
				cellClassName: 'actions',
				headerName: 'Actions',
				hideable: false,
				flex: 1,
				filterable: false,
				sortable: false,
				getActions: ({ id }) => {
					return [
						<>
							<Tooltip title='Update TN Address'>
								<span>
									<IconButton
										id='action-button'
										onClick={() => {
											handleUpdateTNAddressClick(Number(id))
										}}>
										<SettingsOutlinedIcon />
									</IconButton>
								</span>
							</Tooltip>
						</>,
					]
				},
			})

			setTNColumns(initialTNColumns)
			setAllColumnsSet(true)
		}
		if (ddiStatusList.length > 0) {
			setDDIStatusFilterList(ddiStatusList)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ddiList])

	return (
		<>
			{/* Datagrid: TNs */}
			{allColumnsSet && (
				<StyledDataGrid
					rows={tnRows}
					columns={tnColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					//columnVisibilityModel={columnVisibilityModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.ID}
					autoHeight={true}
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
				/>
			)}

			{/* Modal to update TN location */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					<DDIAddressUpdate
						isRange={false}
						ddiDisplay={selectedDDIDisplay}
						addressMapList={filterAddressMapList}
						handleAdressUpdate={handleAdressUpdate}
						onClose={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default TNDisplay
