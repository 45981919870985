import React, { useState, useEffect } from 'react'
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { EditableTableRow } from './EditableTableRow/EditableTableRow'
import { GenericColumn } from '../../../../../../../../utils/interfaces/ComponentModels'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { StyledFilterChip } from '../../../../../../../../styles/styledComponents/displays/StyledFilterChip'
import { GenericPagination } from './GenericPagination/GenericPagination'
import { StyledTextField } from '../../../../../../../../styles/styledComponents/inputs/StyledTextField'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { StyledButton } from '../../../../../../../../styles/styledComponents/inputs/StyledButton'

interface Props {
	data: any[]
	columns: GenericColumn[]
	rowIdKey: string
	// Global search
	globalSearchValue?: string
	onGlobalSearchChange?: (value: string) => void
	// Editable - These functions are REQUIRED and need to be used when you need to make a table editable
	// Current capability - Bulk save
	isEditable?: boolean
	onChange?: (rowId: any, field: string, newValue: any) => void
	onSave?: () => void
	onCancel?: () => void
	disableRadioButtonsOrderConfirmation: () => void
}

export function AddOrderSummaryTable({
	data,
	columns,
	rowIdKey,
	isEditable,
	globalSearchValue,
	onGlobalSearchChange,
	onChange,
	onSave,
	onCancel,
	disableRadioButtonsOrderConfirmation,
}: Props) {
	// Constants
	const [columnFilterValues, setColumnFilterValues] = useState<{
		[key: string]: string
	}>({})
	const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
		useState<null | HTMLElement>(null)
	const [isInEditMode, setIsInEditMode] = useState(false)
	const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
		{}
	)
	const [page, setPage] = useState(0)
	const [rowsPerPage] = useState(10)

	// for use in validation errors
	const [hasValidationError, setHasValidationError] = useState(false)

	// Theme
	const theme = useTheme()

	useEffect(() => {
		// Initialize column widths to an equal value
		const initialWidth = 100 / columns.length
		const widths: { [key: string]: number } = {}
		columns.forEach((column) => {
			widths[column.ID] = initialWidth
		})
		setColumnWidths(widths)
	}, [columns])

	// Opens the popup menu for column filters
	const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
		setFilterMenuAnchorEl(event.currentTarget)
	}

	// Closes the popup menu for column filters
	const handleCloseFilterMenu = () => {
		setFilterMenuAnchorEl(null)
	}

	// Updates column filter value
	const handleColumnFilterChange = (columnId: string, value: string) => {
		setColumnFilterValues({
			...columnFilterValues,
			[columnId]: value,
		})
	}

	// Remove column filter
	const removeColumnFilter = (columnId: string) => {
		const updatedFilters = { ...columnFilterValues }
		delete updatedFilters[columnId]
		setColumnFilterValues(updatedFilters)
	}

	const onCancelEdit = () => {
		// NB!! If you are using editable mode, you need to ensure you pass it onCancel and in that function you need to revert the changes made back if the user cancels
		if (onCancel) {
			onCancel()
		}

		setIsInEditMode(false)
	}

	const onSaveEdit = () => {
		if (onSave) {
			onSave()
		}

		setIsInEditMode(false)
	}

	// Filtered Data return
	const filteredData = data.filter((row) =>
		Object.entries(columnFilterValues).every(([columnId, filterValue]) => {
			const columnValue = row[columnId]?.toString().toLowerCase()
			return columnValue.includes(filterValue.toLowerCase())
		})
	)

	// Pagination
	const handlePageChange = (newPage: number) => {
		setPage(newPage)
	}

	const paginatedData = filteredData.slice(
		page * rowsPerPage,
		(page + 1) * rowsPerPage
	)

	const handleEdit = () => {
		if (!isInEditMode) {
			disableRadioButtonsOrderConfirmation()

			setIsInEditMode(true)
		}
	}

	return (
		<TableContainer>
			{/* Global Search and the edit button */}
			<Box display='flex' justifyContent='space-between' padding='15px 0'>
				<Box
					display='flex'
					flexDirection='row'
					columnGap='20px'
					alignItems='center'>
					{globalSearchValue !== undefined && (
						<TextField
							label='Search'
							value={globalSearchValue}
							onChange={(e) =>
								onGlobalSearchChange && onGlobalSearchChange(e.target.value)
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					)}
					{/* Button to open filter menu */}
					<Tooltip title='Filter Results'>
						<StyledButton
							startIcon={<FilterAltOutlinedIcon />}
							sx={{}}
							variant='outlined'
							onClick={handleOpenFilterMenu}>
							Filter
						</StyledButton>
					</Tooltip>
				</Box>
				<Menu
					anchorEl={filterMenuAnchorEl}
					open={Boolean(filterMenuAnchorEl)}
					onClose={handleCloseFilterMenu}>
					{columns.map((column) => (
						<MenuItem key={column.ID}>
							<StyledTextField
								label={column.Label}
								value={columnFilterValues[column.ID] || ''}
								onChange={(e) =>
									handleColumnFilterChange(column.ID, e.target.value)
								}
							/>
							<IconButton
								size='small'
								onClick={() => removeColumnFilter(column.ID)}>
								<CancelOutlinedIcon />
							</IconButton>
						</MenuItem>
					))}
				</Menu>
				{/* Button Group */}
				<Box display='flex' alignItems='center'>
					{isEditable && !isInEditMode && (
						<Button
							variant='text'
							startIcon={<EditNoteOutlinedIcon />}
							aria-label='edit'
							onClick={handleEdit}>
							Edit
						</Button>
					)}
					{isEditable && isInEditMode && (
						<Box display='flex' flexDirection='row' columnGap='5px'>
							<Button
								startIcon={<SaveOutlinedIcon />}
								disabled={hasValidationError}
								onClick={onSaveEdit}>
								Save
							</Button>
							<Button startIcon={<CancelOutlinedIcon />} onClick={onCancelEdit}>
								Cancel
							</Button>
						</Box>
					)}
				</Box>
			</Box>
			{/* Column Filters */}
			{Object.entries(columnFilterValues).filter(
				([columnId, filterValue]) => filterValue
			).length > 0 && (
				<Box
					display='flex'
					flexDirection='row'
					columnGap='10px'
					alignItems='center'
					padding='15px 0'>
					<Typography>Filtering by:</Typography>
					{Object.entries(columnFilterValues).map(
						([columnId, filterValue]) =>
							filterValue && (
								<StyledFilterChip
									key={columnId}
									label={`${
										columns.find((column) => column.ID === columnId)?.Label
									}: ${filterValue}`}
									onDelete={() => removeColumnFilter(columnId)}
								/>
							)
					)}
				</Box>
			)}
			<Table
				style={{
					boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
					backgroundColor:
						theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
				}}>
				<TableHead>
					<TableRow>
						{columns.map((column) => (
							<TableCell
								key={column.ID}
								style={{
									width: `${columnWidths[column.ID]}%`,
									height: '36px',
									background: theme.palette.secondary.main,
								}}>
								{column.Label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedData.map((row) => (
						<EditableTableRow
							key={row[rowIdKey] + ''}
							row={row}
							columns={columns}
							isInEditMode={isInEditMode}
							setHasValidationError={setHasValidationError}
							onChange={(rowId: number, field: string, newValue: any) => {
								if (onChange) {
									onChange(row[rowIdKey], field, newValue)
								}
							}}
						/>
					))}
				</TableBody>
			</Table>
			<Box display='flex' justifyContent='flex-end' padding='15px 0 0'>
				<GenericPagination
					page={page}
					pageCount={Math.ceil(filteredData.length / rowsPerPage)}
					onChange={handlePageChange}
				/>
			</Box>
		</TableContainer>
	)
}
