import {
	DataGridProProps,
	GridColDef,
	GridColumnVisibilityModel,
	GridFilterModel,
	GridRenderCellParams,
	GridToolbar,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { OrdersDisplay } from '../../../../../utils/interfaces/ComponentModels'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	OrderStatuses,
	Permissions,
	Roles,
} from '../../../../../utils/enums/enums'
import {
	Box,
	Modal,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	styled,
	Alert,
	useTheme,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import {
	Customer,
	Order,
	OrderSKU,
	SKUPartnerMap,
} from '../../../../../utils/interfaces/DBModels'
import { PermissionChecker } from '../../../../../utils/customHooks/PermissionChecker/PermissionChecker'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { StyledButton } from '../../../../../styles/styledComponents/inputs/StyledButton'
import PartnerUpdate from '../../SkuUpdateAndOrderConfirmation/OrderSKUPriceUpdate'
import AddOrder from './AddOrder/AddOrder'
import { showErrorToast } from '../../../../../utils/helperFunctions/helperFunctions'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import ConfirmOrder from '../../SkuUpdateAndOrderConfirmation/ConfirmOrder'
import EmptyDisplay from '../../../displays/EmptyDisplay/EmptyDisplay'

const customBoxStyle = {
	display: 'flex',
	flexDirection: 'row',
	columnGap: '5px',
	alignItems: 'center',
}

const OrdersOverviewDisplay = ({
	ordersDisplay,
	orderList,
	customerList,
	skuPartnerMapList,
	orderSKUList,
	standardNoRangeNoTermSKUs,
	numberOfRows,
	getOrders,
	isCustomerOverview = false,
}: {
	ordersDisplay: OrdersDisplay[]
	orderList: Order[]
	customerList: Customer[]
	skuPartnerMapList: SKUPartnerMap[]
	orderSKUList: OrderSKU[]
	standardNoRangeNoTermSKUs: SKUPartnerMap[]
	numberOfRows: number
	getOrders?: any
	isCustomerOverview?: boolean
}) => {
	// Global variables
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const theme = useTheme()

	// Hooks
	const { modifyData } = UseCrud()

	// Permission Checker
	const checkForPermission = PermissionChecker()

	// Flags
	const [columnsAdded, setColumnsAdded] = useState(false)
	const [isPartnerMRCClicked, setIsPartnerMRCClicked] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [hasRows, setHasRows] = useState<boolean | undefined>(undefined)

	const [externalOrderID, setExternalOrderID] = useState('')
	const [currentMRCPrice, setCurrentMRCPrice] = useState(0)
	const [currentNRCPrice, setCurrentNRCPrice] = useState(0)
	const [orderSKUIDToUpdate, setOrderSKUIDToUpdate] = useState(0)
	const [openOrderFormModal, setOpenOrderFormModal] = useState(false)

	// Display constants
	const [orderRows, setOrderRows] = useState([] as OrdersDisplay[])

	// *** Datagrid Specific Variables *** //
	// Pagination
	const [paginationModel, setPaginationModel] = useState({
		pageSize: numberOfRows,
		page: 0,
	})
	// Column Visibility: On default Customer Name, Reference and actions should be hidden
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({
			CustomerName: false,
			actions: false,
			CustomerPartnerReference: false,
			OrderSKUID: false,
			ExternalOrderID: false,
		} as GridColumnVisibilityModel)
	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	//info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: theme.palette.secondary.main,
		color: 'black',
		fontSize: '14px',
	})

	// Flag - Hide actions for now until add DDI, Trunks done - To be removed *****
	const hideActions = true

	// Column Definition: Order Table
	const initialOrderColumns: GridColDef[] = [
		{
			field: 'OrderID',
			headerName: 'Order ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PartnerOrderReference',
			headerName: 'Order Reference',
			hideable: false,
			flex: 1,
		},
		isCustomerOverview
			? (null as any)
			: {
					field: 'CustomerPartnerReference',
					headerName: 'Customer Reference',
					hideable: false,
					flex: 1,
			  },
		isCustomerOverview
			? (null as any)
			: {
					field: 'CustomerName',
					headerName: 'Customer Name',
					hideable: false,
					flex: 1,
			  },
		{
			field: 'OrderStatus',
			headerName: 'Order Status',
			hideable: false,
			flex: 1,
			// Note - Removed the display for the chip for now until we find a better style
		},
		{
			field: 'OrderType',
			headerName: 'Order Type',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PreviousOrderID',
			headerName: 'Previous Order ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'ExternalOrderID',
			headerName: 'Generated Order ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'SipcomDirect',
			headerName: 'Sipcom Direct',
			hideable: false,
			flex: 1,
			type: 'boolean',
		},
	].filter(Boolean)
	const [orderColumns, setOrderColumns] =
		useState<GridColDef[]>(initialOrderColumns)

	// Column Definition: Order SKU Table
	const orderSKUColumns: GridColDef[] = [
		{
			field: 'OrderSKUID',
			headerName: 'Order SKU ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'SKUTypeID',
			headerName: 'SKU',
			hideable: false,
			flex: 1,
		},
		{
			field: 'Quantity',
			headerName: 'Quantity',
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderSKUTerm',
			headerName: 'SKU Term',
			flex: 1,
			// Render actions: Allow edit of SKU Term only if order price SKU price is required
		},
		{
			field: 'OrderSKUStatusID',
			headerName: 'SKU Status',
			hideable: false,
			flex: 1,
		},
		{
			field: 'ActivationDate',
			renderHeader: () => (
				<>
					<Tooltip title='Activation Date of the first SKU of each Order is deemed to be the Commencement Date'>
						<InfoAlert severity='info' style={{ padding: '0' }}>
							{'Activation Date'}
						</InfoAlert>
					</Tooltip>
				</>
			),
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<any>) => (
				<>
					<Box sx={customBoxStyle}>
						{params.value ? (
							<>
								<Typography component='p'>
									{new Date(params.value).toLocaleDateString()}{' '}
								</Typography>
							</>
						) : (
							''
						)}
					</Box>
				</>
			),
		},
		{
			field: 'CurrentProduct',
			headerName: 'Current Product',
			hideable: false,
			flex: 1,
		},
		// Note - Removed edit logic
	]

	// *** Handle functions *** //
	// Add columns if partner admin
	const addColumnsForPartnerAdmin = () => {
		// For now hide actions until further notice
		if (hideActions) {
			// Get initial array and add to it
			var columns = initialOrderColumns

			// Push to columns
			columns.push({
				field: 'confirmOrder',
				type: 'actions',
				cellClassName: 'actions',
				headerName: 'Confirm Order',
				width: 100,
				flex: 2,
				editable: false,
				align: 'center',
				hideable: false,
				// Render actions - Display a tick (Completed Order) or edit icon (Order needs confirmation)
				getActions: ({ id }) => {
					return [
						<>
							{/* Main icon button - Opens menu to add DDIs, Trunks or E911 */}
							<Tooltip
								title={
									handleIsOrderConfirmRequired(Number(id))
										? 'Please Confirm Order.'
										: 'Order is Confirmed.'
								}>
								<span>
									<IconButton
										id='confirm-order-button'
										disabled={!handleIsOrderConfirmRequired(Number(id))}
										sx={{
											opacity: !handleIsOrderConfirmRequired(Number(id))
												? '0.5'
												: '1',
											filter: !handleIsOrderConfirmRequired(Number(id))
												? 'grayscale(1)'
												: '',
										}}>
										{handleIsOrderConfirmRequired(Number(id)) ? (
											<ConfirmOrder
												orderID={Number(id)}
												handleConfirmOrder={handleConfirmOrder}
											/>
										) : (
											<CheckCircleOutlineOutlinedIcon />
										)}
									</IconButton>
								</span>
							</Tooltip>
						</>,
					]
				},
			})

			// Set new columns
			setOrderColumns(columns)
		}

		// add Order SKU columns for Partner
		orderSKUColumns.push(
			{
				field: 'Price',
				headerName: 'MRC (per unit)',
				flex: 1,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
					<>
						<Box sx={customBoxStyle}>
							<Typography component='p'>{params.value + ''}</Typography>
							{isAwaitingPartnerApproval(Number(params.id)) &&
								roleID === Roles.PartnerAdmin && (
									<Tooltip title='Update Order MRC Price'>
										<IconButton
											onClick={() => {
												setIsPartnerMRCClicked(true)
												handleMRCChange(params)
											}}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
						</Box>
					</>
				),
				// Render actions: Allow edit of MRC only if order price SKU price is required
			},
			{
				field: 'NRC',
				headerName: 'NRC (per unit)',
				flex: 1,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
					<>
						<Box sx={customBoxStyle}>
							<Typography component='p'>{params.value + ''}</Typography>
							{isAwaitingPartnerApproval(Number(params.id)) &&
								roleID === Roles.PartnerAdmin && (
									<Tooltip title='Update Order NRC Price'>
										<IconButton
											onClick={() => {
												setIsPartnerMRCClicked(false)
												handleNRCChange(params)
											}}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
						</Box>
					</>
				),
				// Render actions: Allow edit of NRC only if order price SKU price is required
			},
			{
				field: 'OrderCurrency',
				headerName: 'Order Currency',
				hideable: false,
				flex: 1,
			}
		)
		// Set column visibility - Fields should be shown for partner only
		setColumnVisibilityModel({
			CustomerName: true,
			actions: true,
			CustomerPartnerReference: true,
		})
	}

	useEffect(() => {
		// Set rows
		if (ordersDisplay && ordersDisplay.length > 0) {
			setOrderRows(ordersDisplay)
			setHasRows(true)
		} else {
			setHasRows(false)
		}
		// Add additional columns if user is a partner admin
		if (roleID === Roles.PartnerAdmin) {
			if (!columnsAdded) {
				setColumnsAdded(true)
				addColumnsForPartnerAdmin()
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderList, orderSKUList, columnsAdded])

	// Handle functions
	const handleMRCChange = async (params: any) => {
		if (params.id) {
			var _orderInfo = orderSKUList?.find((o) => o.OrderSKUID === params.id)

			if (_orderInfo) {
				var _orderExternalID = orderList.find(
					(ex) => ex.OrderID === _orderInfo?.OrderID
				)

				if (_orderExternalID) {
					setExternalOrderID(_orderExternalID.ExternalOrderID ?? '')
					setOrderSKUIDToUpdate(Number(_orderInfo.OrderSKUID))
				}
			}
			setCurrentMRCPrice(params.value)
			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleNRCChange = async (params: any) => {
		if (params.id) {
			var _orderInfo = orderSKUList?.find((o) => o.OrderSKUID === params.id)

			if (_orderInfo) {
				var _orderExternalID = orderList.find(
					(ex) => ex.OrderID === _orderInfo?.OrderID
				)

				if (_orderExternalID) {
					setExternalOrderID(_orderExternalID.ExternalOrderID ?? '')
					setOrderSKUIDToUpdate(Number(_orderInfo.OrderSKUID))
				}
			}
			setCurrentNRCPrice(params.value ?? '')
			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	function isAwaitingPartnerApproval(orderSkuID: number): boolean | undefined {
		var matchingOrderSku = orderSKUList.find((x) => x.OrderSKUID === orderSkuID)
		if (matchingOrderSku) {
			var matchingOrder = orderList.find(
				(x) => x.OrderID === Number(matchingOrderSku?.OrderID)
			)
			return (
				matchingOrder?.OrderStatusID === OrderStatuses.AwaitingPartnerApproval
			)
		}
	}

	const handlePartnerPriceUpdate = async (
		externalOrderID: string,
		orderSKUPrice: string
	) => {
		if (externalOrderID && orderSKUPrice) {
			const _orderSKUToAddObj: OrderSKU = {
				OrderSKUID: orderSKUIDToUpdate,
				IsPriceBookPricing: false,
				...(isPartnerMRCClicked
					? { Price: Number(orderSKUPrice) }
					: { NRC: Number(orderSKUPrice) }),
			}

			//Forward to sales for approval
			var _orderToFind = orderList.find(
				(o) => o.ExternalOrderID === externalOrderID
			)

			if (_orderToFind) {
				const _operationPerformed = isPartnerMRCClicked ? 'MRC' : 'NRC'
				try {
					// Post to DB
					var postSuccess = await modifyData({
						UserName: loggedInUser.email,
						FileAndFunctionName: `OrderSKUPriceUpdate.tsx: Update ${_operationPerformed} price`,
						QueryURL: 'UpdateV2?Params=OrderSKU',
						QueryObj: {
							OrderSKU: _orderSKUToAddObj,
						},
						ShowSuccessMessage: true,
						SuccessMessage: `Successfully updated ${_operationPerformed} price.`,
						ShowErrorMessage: false,
						ErrorMessage: `Failed to update ${_operationPerformed} price`,
						LogErrorToDB: true,
					})

					if (postSuccess) {
						await getOrders()
						handleCloseModal()
					} else {
						showErrorToast(`Invalid ${_operationPerformed} price entered`)
					}
				} catch (error) {
					showErrorToast(
						`An error occurred when trying to update ${_operationPerformed} price`
					)
				}
			}
		}
	}

	const handleConfirmOrder = async (orderIDToUpdate: number) => {
		if (orderIDToUpdate && orderIDToUpdate > 0) {
			var _orderToFind = orderList.find(
				(o) => Number(o.OrderID) === orderIDToUpdate
			)

			if (_orderToFind) {
				var _orderStatusIDToUpdateObj = {
					Order: {
						OrderID: _orderToFind.OrderID,
						OrderStatusID: OrderStatuses.PartnerApproved,
					},
				}
				try {
					// Post to DB
					var postSuccess = await modifyData({
						UserName: loggedInUser.email,
						FileAndFunctionName: `ConfirmOrder.tsx: Partner Approving Order Status`,
						QueryURL: 'UpdateV2?Params=Order',
						QueryObj: _orderStatusIDToUpdateObj,
						ShowSuccessMessage: true,
						SuccessMessage: `Successfully approved order.`,
						ShowErrorMessage: false,
						ErrorMessage: `Failed to confirm order`,
						LogErrorToDB: true,
					})

					if (postSuccess) {
						await getOrders()
						handleCloseModal()
					} else {
						showErrorToast(`Failed to confirm order`)
					}
				} catch (error) {
					showErrorToast(`An error occurred when trying to confirm order`)
				}
			} else {
				showErrorToast('Invalid Order ID')
			}
		}
	}

	// Add order info content panel - Used to display orderSKU table only for partner admin
	function OrderSKUContent({ row: rowProp }: { row: OrdersDisplay }) {
		return (
			<Box display='flex' flexDirection='column' rowGap='20px' padding={3}>
				<Typography component='h2'>List of Order SKUs</Typography>
				<StyledDataGrid
					columns={orderSKUColumns}
					columnVisibilityModel={columnVisibilityModel}
					rows={rowProp.OrderSKUPriceDisplay}
					getRowId={(row) => row.OrderSKUID}
					autoPageSize={true}
					autoHeight={true}
					hideFooter
				/>
			</Box>
		)
	}

	// Detail panel of main datagrid - Order SKU Display
	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	>(({ row }) => <OrderSKUContent row={row} />, [])

	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// *** Handle Functions *** //
	// Handle if order has been confirmed or not - Check for Order status ID
	const handleIsOrderConfirmRequired = (orderID: number) => {
		// Return variable
		var isOrderConfirmRequired = false

		// Get current order
		var currentOrder = orderList.find(
			(o) => Number(o.OrderID) === Number(orderID)
		)
		if (
			currentOrder &&
			currentOrder.OrderStatusID === OrderStatuses.AwaitingPartnerApproval
		) {
			isOrderConfirmRequired = true
		}
		return isOrderConfirmRequired
	}

	const handleOpenOrderFormModal = () => {
		setOpenOrderFormModal(true)
	}

	const handleCloseOrderFormModal = () => {
		setOpenOrderFormModal(false)
		//get latest orders
		getOrders()
	}

	return (
		<Box className='order-overview-container'>
			{isCustomerOverview === false && (
				<>
					{/* Order status filter */}
					<Box className='order-overview-filters'>
						<Box className='description'>
							<Typography component='p'>
								Manage the orders, view SKUs and customer info
							</Typography>
						</Box>

						<Box>
							{checkForPermission(Permissions.AddOrders) && (
								<StyledButton
									startIcon={
										<img
											src={require('../../../../../assets/images/generic/icons/plus-icon.png')}
											alt='plus icon'
											style={{ width: '10px', height: '10px' }}
										/>
									}
									onClick={handleOpenOrderFormModal}>
									Add new order
								</StyledButton>
							)}
						</Box>
					</Box>

					<Divider />
				</>
			)}
			{/* Datagrid: Orders (Parent) and Order SKUs (Child) */}

			{hasRows === true && (
				<StyledDataGrid
					rows={orderRows}
					columns={orderColumns}
					editMode='row'
					checkboxSelection={false}
					pagination
					paginationModel={paginationModel}
					columnVisibilityModel={columnVisibilityModel}
					onPaginationModelChange={setPaginationModel}
					pageSizeOptions={[5]}
					slots={{
						pagination: CustomPagination,
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.OrderID}
					autoHeight={true}
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
				/>
			)}

			{/* open modal */}
			<Modal open={openOrderFormModal} onClose={handleCloseOrderFormModal}>
				<StyledModal width={700}>
					<Box className='order-form-content'>
						<AddOrder
							customerList={customerList}
							skuPartnerMapList={skuPartnerMapList}
							standardNoRangeNoTermSKUs={standardNoRangeNoTermSKUs}
							handleCloseOrderFormModal={handleCloseOrderFormModal}
						/>
					</Box>
				</StyledModal>
			</Modal>

			{/* Modal to update sku order price */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					<PartnerUpdate
						externalOrderID={externalOrderID}
						partnerMRCPrice={currentMRCPrice.toString()}
						partnerNRCPrice={currentNRCPrice.toString()}
						handlePartnerPriceUpdate={handlePartnerPriceUpdate}
						onClose={handleCloseModal}
						isPartnerMRCClicked={isPartnerMRCClicked}
					/>
				</StyledModal>
			</Modal>
			{hasRows === false ? (
				<EmptyDisplay
					title='No orders found'
					description='There were no orders found'
				/>
			) : null}
		</Box>
	)
}

export default OrdersOverviewDisplay
